import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { useHistory, Link } from "react-router-dom";
import { ReactComponent as IconCheck } from "../../icons/IconCheck.svg";
import "./FAB.css";
import AppLabelsContext from '../../../App/AppLabelsContext';

export const FAB = (props) => {
  const {
    isFormValid,
    isAPISubmitValid,
    navigateURL,
    navigateURLStateFrom,
    isAddItemType,
    resetInvalidForm,
    isModalFAB,
    isBackgroundGradient,
    isLink,
    isModalTrigger,
    isLeftAligned,
    customClass,
    buttonText,
    formError,
    errorDetails,
    id,
    onClick,
    formId,
    onSubmit,
    buttonSuccessText,
    isAddToOrder,
    isResetEnabled = true,
    FABCustomClass,
    customStyle = {},
    contrastTextColor,
    isRelativePositioning,
    relativeContainerId,
    cartButton,
    isBufferBeforeNavigationDisabled,
    attachToBottomOfMainContent = false,
  } = props;

  let container =
    isRelativePositioning && relativeContainerId && document.getElementById(relativeContainerId)
      ? document.getElementById(relativeContainerId)
      : document.body;
  if (attachToBottomOfMainContent && document.getElementById("portal-container-wrapper")) {
    container = document.getElementById("portal-container-wrapper")
  }
  const history = useHistory();
  const appLabels = useContext(AppLabelsContext)

  const [isDisabled, setIsDisabled] = useState(false);
  const [formErrorHideClass, setFormErrorHideClass] = useState("");
  const [isCheckIcon, setIsCheckIcon] = useState(false);

  useEffect(() => {
    let timer1;
    let timer2;
    let timer3;
    let timer4;
    let timer5;

    //TODO: simplify the validation mechanism
    if (isFormValid) {
      // form is valid

      if (isAPISubmitValid) {
        // form API submit was successful

        if (navigateURL && navigateURL !== "") {
          setIsCheckIcon(true);
          if (!isBufferBeforeNavigationDisabled) {
            // add some buffer time before navigating on successful API submit
            timer1 = setTimeout(() => {
              history.push({
                pathname: navigateURL,
                state: navigateURLStateFrom ? { from: navigateURLStateFrom } : null,
              });
            }, 2000);
          } else {
            history.push({
              pathname: navigateURL,
              state: navigateURLStateFrom ? { from: navigateURLStateFrom } : null,
            });
          }
        }
      } else if (isAddItemType) {
        setIsCheckIcon(true);

        // Reset validity for add item FAB
        timer2 = setTimeout(() => {
          setFormErrorHideClass(" FAB__error--closing");

          // Remove error from DOM after hiding animation is done
          timer3 = setTimeout(() => {
            if (resetInvalidForm) resetInvalidForm();
            setFormErrorHideClass("");
            setIsDisabled(false);
          }, 300);
        }, 2000);
      }
    } else if (!isFormValid && isFormValid != null && isResetEnabled) {
      // form is invalid

      // Start hiding the error after 2 seconds
      timer4 = setTimeout(() => {
        setFormErrorHideClass(" FAB__error--closing");

        // Remove error from DOM after hiding animation is done
        timer5 = setTimeout(() => {
          if (resetInvalidForm) {
            // not all FABs are tied to forms
            resetInvalidForm();
          }
          setFormErrorHideClass("");
          setIsDisabled(false);
        }, 300);
      }, 5000);
    } else if (isFormValid == null && isResetEnabled) {
      //Reset validation
      setIsDisabled(false);
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
      clearTimeout(timer5);
    };
  }, [isFormValid, isAPISubmitValid]);

  useEffect(() => {
    setIsDisabled(props.isDisabled);
  }, [props.isDisabled]);

  return ReactDOM.createPortal(
    <div
      className={`FAB-wrapper${isModalFAB ? " FAB-wrapper--modal" : ""} ${FABCustomClass || ""}`}
    >
      {isBackgroundGradient && (
        <div
          className={`FAB-wrapper__gradient${
            (!isFormValid && isFormValid != null) || !isResetEnabled
              ? " FAB-wrapper__gradient--expanded"
              : ""
          }`}
        />
      )}
      {((!isFormValid && isFormValid != null) || !isResetEnabled) && (<>
        <div className={`FAB__error${formErrorHideClass}`} aria-live="assertive" aria-atomic="false">{formError}
          <p className="visually-hidden" >{appLabels["form"]["please-correct-following-errors"]}</p>
          {errorDetails && errorDetails.map((e) => <p className="visually-hidden" key={e}>{e}</p>)}
        </div>
       
      </>
      )}
      <div className="desktop-container" style={cartButton ? { position: "relative" } : {}}>
        {cartButton !== null && cartButton}
        {!isLink ? (
          <>
            {isModalTrigger ? (
              <button
                onClick={(e) => {
                  if (onClick) onClick(e);
                }}
                id={id}
                className={`FAB${isLeftAligned ? " FAB--left-aligned" : ""} ${customClass || ""}`}
                disabled={isDisabled ? isDisabled : isDisabled}
                type="button"
              >
                {buttonText}
              </button>
            ) : (
              <button
                onClick={(e) => {
                  setIsDisabled(true);
                  if (onSubmit) onSubmit(e);
                }}
                id="button--submit-form"
                form={formId}
                className={`FAB button button-primary${isLeftAligned ? " FAB--left-aligned" : ""} ${
                  customClass ? customClass : ""
                }${isAddToOrder ? " FAB--add-to-order" : ""}`}
                disabled={isDisabled ? isDisabled : isDisabled}
                type="submit"
                style={customStyle}
              >
                {isFormValid && (isAPISubmitValid || isAddItemType) ? (
                  <div className="FAB__success">
                    <span>{buttonSuccessText}</span>
                    {isCheckIcon && (
                      <IconCheck style={contrastTextColor ? { color: contrastTextColor } : {}} />
                    )}
                  </div>
                ) : (
                  buttonText
                )}
              </button>
            )}
          </>
        ) : (
          <Link
            to={{
              pathname: navigateURL,
              state: navigateURLStateFrom ? { from: navigateURLStateFrom } : null,
            }}
            className={`FAB FAB--link ${isLeftAligned ? " FAB--left-aligned" : ""} ${
              customClass || ""
            }${isAddToOrder ? " FAB--add-to-order" : ""}`}
          >
            {buttonText}
          </Link>
        )}
      </div>
    </div>,
    container
  );
};
