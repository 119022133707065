import React, { useEffect, useState, useRef } from "react";
import useWindowSize from "../../../../_common/hooks/useWindowSize";

/** UI Components */
import { AddonOrModifierGroup } from "./AddonOrModifierGroup";

/** Helpers */
import { formatOptionGroups } from "../../helpers/orderingHelpers";
import { findPos } from "../../../../_common/helpers";

export const AddonsOrModifiers = (props) => {
  /** This Component will contain all the addon/modifier groups */
  const {
    menuItem,
    updateMenuItem,
    cartItem,
    isInCart,
    addonsOutOfStock,
    comboChildItem,
    currentActiveOptionGroupIndex,
    updateActiveOptionGroupIndex,
    isStepByStepOrderingEnabled,
    displayType,
    setAddToOrderButtonText,
    updateOrderingState,
    orderingState,
    formattedOptionGroups,
    setFormattedOptionGroups,
    performAutoScroll,
    isEditingComboItem,
    comboCartItem,
    skin,
    savedMenuItemModifiers = null
  } = props;

  /** This flag will determine if the selected combo child item
   * is the same as the original selection when combo was added to cart */
  const isTheSameChildItem =
    cartItem &&
    comboChildItem &&
    cartItem.combo_child_items &&
    cartItem.combo_child_items[comboChildItem.group_id - 1] &&
    cartItem.combo_child_items[comboChildItem.group_id - 1].id === comboChildItem.id;

  const deviceWidth = useWindowSize().width;

  const [resetOptionsGroup, setResetOptionsGroup] = useState(true);

 
  const isFormattingOptions = useRef(false);
  useEffect(() => {
    let tempFormattedGroups = {};
    const formatOptions = async () => {
      /** Format Modifier groups */
      const formattedModifierGroups = await formatOptionGroups(
        menuItem.modifierGroups,
        isInCart,
        resetOptionsGroup,
        isEditingComboItem,
        skin, 
        savedMenuItemModifiers
      );

      Object.keys(formattedModifierGroups).forEach((key) => {
        tempFormattedGroups[key] = formattedModifierGroups[key];
      });

      /** Format Addon groups */
      const formattedAddonGroups = await formatOptionGroups(
        menuItem.addonGroups,
        isInCart,
        resetOptionsGroup,
        isEditingComboItem,
        skin,
        savedMenuItemModifiers
      );

      Object.keys(formattedAddonGroups).forEach((key) => {
        tempFormattedGroups[key] = formattedAddonGroups[key];
      });

      Object.keys(tempFormattedGroups).forEach((key, index) => {
        tempFormattedGroups[key].positioningTemplateIndex = index;
      });

      setFormattedOptionGroups(tempFormattedGroups);
      isFormattingOptions.current = false;
    };

    isFormattingOptions.current = true;
    formatOptions();
  }, [resetOptionsGroup, menuItem]);

  useEffect(() => {
    if (formattedOptionGroups !== null && Object.keys(formattedOptionGroups).length > 0) {
      const optionGroupKey = Object.keys(formattedOptionGroups)[currentActiveOptionGroupIndex];

      if (optionGroupKey) {
        const containerId = formattedOptionGroups[optionGroupKey].id;
        const optionGroupContainer = document.getElementById(`${containerId}-header`);
        const optionGroup = formattedOptionGroups[optionGroupKey];

        if (
          isStepByStepOrderingEnabled &&
          performAutoScroll.current &&
          orderingState &&
          !orderingState.hideAllButtons
        ) {
          setTimeout(() => {
            if (
              !comboChildItem &&
              document.getElementsByClassName("item-details-main__Wrapper").length > 0 &&
              optionGroupContainer
            ) {
              if (optionGroup.positioningTemplateIndex === 0) {
                document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
                  top: findPos(optionGroupContainer) - (deviceWidth >= 660 ? 160 : 227),
                  behavior: "smooth",
                });
              }
            } else if (
              comboChildItem &&
              document.getElementsByClassName("item-details-main__Wrapper").length > 0 &&
              optionGroupContainer
            ) {
              if (optionGroup.positioningTemplateIndex === 0) {
                document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                document.getElementsByClassName("item-details-main__Wrapper")[0].scrollTo({
                  top: findPos(optionGroupContainer) - (deviceWidth >= 660 ? 190 : 207),
                  behavior: "smooth",
                });
              }
            }

            performAutoScroll.current = false;
          }, 500);
        }
      }
    }
  }, [currentActiveOptionGroupIndex, formattedOptionGroups, orderingState]);

  const useMenuItemCopy = useRef(false);
  return (
    <>
      {!!Object.keys(formattedOptionGroups).length && (
        <div className="item-detail__options-group">
          {Object.keys(formattedOptionGroups).map((key) => (
            <React.Fragment key={key}>
              {((isStepByStepOrderingEnabled &&
                formattedOptionGroups[key].positioningTemplateIndex <=
                  currentActiveOptionGroupIndex) ||
                !isStepByStepOrderingEnabled) && (
                <AddonOrModifierGroup
                  menuItem={menuItem}
                  updateMenuItem={updateMenuItem}
                  topLevelOptionGroup={formattedOptionGroups[key]}
                  cartItem={cartItem}
                  isInCart={isInCart}
                  setResetOptionsGroup={setResetOptionsGroup}
                  addonsOutOfStock={addonsOutOfStock}
                  isTheSameChildItem={isTheSameChildItem}
                  comboChildItem={comboChildItem}
                  updateActiveOptionGroupIndex={updateActiveOptionGroupIndex}
                  displayType={displayType}
                  setAddToOrderButtonText={setAddToOrderButtonText}
                  currentOptionGroupIndex={currentActiveOptionGroupIndex}
                  updateOrderingState={updateOrderingState}
                  orderingState={orderingState}
                  isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
                  isEditingComboItem={isEditingComboItem}
                  comboCartItem={comboCartItem}
                  useMenuItemCopy={useMenuItemCopy}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};
