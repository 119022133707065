import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getAllMenuItems} from "../Cart/SuggestedItems/SuggestedItemsHelpers";
import { MenuContext } from "../Menu/MenuContext";
import AppLabelsContext from "../../App/AppLabelsContext";
import { SuggestedItemOrderLevelCard } from "./SuggestedItemOrderLevelCard";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import "./suggetedItemsOrderLevel.css";
import CartContext from "./CartContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import useWindowSize from "../../_common/hooks/useWindowSize";

export const SuggestedItemsOrderLevel = ({
  suggestedItemIds = null,
  aiSuggestedItemIds = null,
  setIsAnySuggestedItemAddedToCart,
  setShowOrderSuggestedItemsDialog
}) => {
  const appLabels = useContext(AppLabelsContext);
  const menu = useContext(MenuContext);
  const cartContext = useContext(CartContext);
  const history = useHistory();

  const [itemLevelSuggestedItems, setItemLevelSuggestedItems] = useState(null); // array of suggested item IDs
  const [aiItemLevelSuggestedItems, setAIItemLevelSuggestedItems] = useState(null); // array of AI suggested item IDs
  const [suggestedItemDetails, setSuggestedItemDetails] = useState([]);
  const [apiSuggetedItemsPresent, setApiSuggestedItemsPresent] = useState("processing");
  const isCategoryBased = menu.menuLevel === "threeLevels";
  const [filteredSuggestedItemsList, setFilteredSuggestedItemsList] = useState([]); // used to monitor current suggested items and if any are added to cart.
  const [loading, setLoading] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  
  const deviceHeight = useWindowSize().height
  const deviceWidth = useWindowSize().width

  useEffect(() => {
    if (suggestedItemIds) {
      setItemLevelSuggestedItems(suggestedItemIds);
    }
    if (aiSuggestedItemIds) {
      setAIItemLevelSuggestedItems(aiSuggestedItemIds);
    }
  }, [suggestedItemIds]);
  //const [duplicateItems,setDuplicateItems] = useState()
  const checkForDuplicates = () => {
    let dupeItemIds = [];
    itemLevelSuggestedItems.forEach((item) => {
      aiItemLevelSuggestedItems.forEach((aiItem) => {
        if (item === aiItem) {
          dupeItemIds.push(item);
        }
      });
    });

    return dupeItemIds;
  };
  useEffect(() => {
    // add menu level based params here
    if (
      menu.apiData &&
      ((itemLevelSuggestedItems && itemLevelSuggestedItems.length > 0) ||
        (aiItemLevelSuggestedItems && aiItemLevelSuggestedItems.length > 0)) &&
      refreshList
    ) {
      setLoading(true);
      let duplicateItems = [];
      if (
        itemLevelSuggestedItems &&
        itemLevelSuggestedItems.length > 0 &&
        aiItemLevelSuggestedItems &&
        aiItemLevelSuggestedItems.length > 0
      ) {
        duplicateItems = checkForDuplicates();
      }
      let allMenuItems = getAllMenuItems(isCategoryBased, menu.apiData);
      let filteredItemLevelSuggestedItemIds = [];
      let filteredAISuggestedItemIds = [];
      if (cartContext && cartContext.value && cartContext.value.length > 0) {
        if (itemLevelSuggestedItems) {
          itemLevelSuggestedItems.forEach((item) => {
            let filteredItem = cartContext.value.filter((cartItem) => cartItem.id === item);
            if (filteredItem.length === 0) {
              filteredItemLevelSuggestedItemIds.push(item);
            }
          });
        }

        if (aiItemLevelSuggestedItems) {
          aiItemLevelSuggestedItems.forEach((item) => {
            let filteredItem = cartContext.value.filter((cartItem) => cartItem.id === item);
            if (filteredItem.length === 0) {
              filteredAISuggestedItemIds.push(item);
            }
          });
        }
      }
      if (filteredItemLevelSuggestedItemIds.length > 0 || filteredAISuggestedItemIds.length > 0) {
        let itemDetails = [];
        filteredItemLevelSuggestedItemIds.forEach((suggestedItem) => {
          let foundItem = allMenuItems.find((item) => suggestedItem === item.id);
          if (foundItem && foundItem.type !== "comboItem") {
            let isDuplicateSuggestedItem = false;
            duplicateItems.forEach((item) => {
              if (suggestedItem === item && !isDuplicateSuggestedItem) {
                isDuplicateSuggestedItem = true;
              }
            });
            if (
              foundItem.item_count !== "0" &&
              foundItem.itemActive === "t" &&
              !isDuplicateSuggestedItem
            ) {
              foundItem.subcategory = foundItem.subcategoryId;
              delete foundItem.subcategoryId;
              foundItem.category = foundItem.categoryId;
              delete foundItem.categoryId;
              foundItem.isSuggestedItem = true
              foundItem.suggestedItemType = "order-level"
              itemDetails.push(foundItem);
            }
          }
        });

        let aiItemDetails = [];
        filteredAISuggestedItemIds.forEach((suggestedItem) => {
          let foundItem = allMenuItems.find((item) => suggestedItem === item.id);
          if (foundItem && foundItem.type !== "comboItem") {
            duplicateItems.forEach((item) => {
              if (suggestedItem === item) {
                foundItem.isSuggestedTwice = true;
              }
            });
            if (foundItem.item_count !== "0" && foundItem.itemActive === "t") {
              foundItem.subcategory = foundItem.subcategoryId;
              delete foundItem.subcategoryId;
              foundItem.category = foundItem.categoryId;
              delete foundItem.categoryId;
              foundItem.isAIrecommendation = true;
              foundItem.isSuggestedItem = true
              foundItem.suggestedItemType = "order-level"
              aiItemDetails.push(foundItem);
            }
          }
        });

        // new AI logic
        let combinedItemDetails = [];
        if (aiItemDetails.length > 0 && itemDetails.length === 0) {
          combinedItemDetails = aiItemDetails;
          if (combinedItemDetails.length > 0) {
            let slicedArray = combinedItemDetails.slice(0, 6);
            combinedItemDetails = slicedArray;
          }
        } else if (aiItemDetails.length === 0 && itemDetails.length > 0) {
          combinedItemDetails = itemDetails;
          if (combinedItemDetails.length > 0) {
            let slicedArray = combinedItemDetails.slice(0, 6);
            combinedItemDetails = slicedArray;
          }
        } else if (aiItemDetails.length > 0 && itemDetails.length > 0) {
          let slicedMerchantItems = itemDetails.slice(0, 3);
          let slicedAIItems = aiItemDetails.slice(0, 3);

          // readjust arrays to make suggested items = 6
          if (slicedAIItems.length < 3) {
            let diff = 6 - slicedAIItems.length
            let remainingMerchantItems = itemDetails.slice(3, diff)
            let newMerchantItems = slicedMerchantItems.concat(remainingMerchantItems)
            slicedMerchantItems = newMerchantItems
          }
          if (itemDetails.length < 3) {
            let diff = 6 - itemDetails.length
            let remainingAIItems = slicedAIItems.slice(3, diff)
            let newAIItems = slicedAIItems.concat(remainingAIItems)
            slicedAIItems = newAIItems
          }
          combinedItemDetails = slicedMerchantItems.concat(slicedAIItems);
        }
        setFilteredSuggestedItemsList(combinedItemDetails);
        setSuggestedItemDetails(combinedItemDetails);
        setApiSuggestedItemsPresent(true);
        setLoading(false);
      } else {
        setApiSuggestedItemsPresent(false);
        setLoading(false);
      }
    } else if (itemLevelSuggestedItems && itemLevelSuggestedItems.length === 0) {
      setApiSuggestedItemsPresent(false);
    }
  }, [itemLevelSuggestedItems, menu]);

  // sets locally generated items as suggested items to show in dialog when apiSuggestedItems are empty or not relevant anymore
  useEffect(() => {
    if (
      cartContext.value &&
      menuData &&
      (!suggestedItemDetails || suggestedItemDetails.length === 0) &&
      apiSuggetedItemsPresent !== "processing" &&
      refreshList
    ) {
      setShowOrderSuggestedItemsDialog(false);
      setLoading(false);
    }
  }, [cartContext, apiSuggetedItemsPresent]);

  const menuData = menu.apiData;

  const updateActiveItem = (id) => {
    let suggestedItemFromArray = suggestedItemDetails.find((item) => item.id === id);

    menu.updateMenu({
      ...menu,
      activeTopCategory: suggestedItemFromArray.category,
      activeSubcategory: suggestedItemFromArray.parentId,
      activeItem: id,
    });

    const itemDestination = `/online-ordering/menu/category/${suggestedItemFromArray.category}/subcategory/${suggestedItemFromArray.parentId}/item/${id}`;

    history.push({
      pathname: itemDestination,
      state: { from: "order-level-suggested-items", source: "item-card", originalItemData: suggestedItemFromArray },
    });
  };

  const { state } = useLocation();
  useEffect(() => {
    if (
      state &&
      (state.from === "suggested-item-edit-page" ||
        state.from === "order-level-item-suggested-page")
    ) {
      setRefreshList(false);
      let storedItems = sessionStorage.getItem(skin + "__storedOrderLevelSuggestedItems");
      setSuggestedItemDetails(JSON.parse(storedItems));
      setFilteredSuggestedItemsList(JSON.parse(storedItems));
    } else {
      setRefreshList(true);
    }
  }, [state]);

  const [itemModifyPanelActive, setItemModifyPanelActive] = useState();
  useEffect(() => {
    if (state && state.from === "suggested-item-edit-page") {
      if (state.customizedItemId) {
        setItemModifyPanelActive(state.customizedItemId);
        let targetItem = document.getElementById(state.customizedItemId);
        if (targetItem) {
          let modifyButton = targetItem.getElementById(
            state.customizedItemId + "-customzieItemButton"
          );
          if (modifyButton) {
            modifyButton.click();
          }
        }
      }
    }
  }, [state]);

  useEffect(() => {
    let filteredItemsList = [];
    if (
      cartContext &&
      cartContext.value &&
      cartContext.value.length > 0 &&
      filteredSuggestedItemsList.length > 0
    ) {
      filteredSuggestedItemsList.forEach((item) => {
        let filteredItem = cartContext.value.filter((cartItem) => cartItem.id === item.id);
        if (filteredItem.length !== 0) {
          filteredItemsList.push(filteredItem);
        }
      });
      if (filteredItemsList.length > 0) {
        setIsAnySuggestedItemAddedToCart(true);
      } else {
        setIsAnySuggestedItemAddedToCart(false);
      }
    }
  }, [cartContext, refreshList, filteredSuggestedItemsList]);

  const skin = useContext(MerchantConfigContext).skin;
  useEffect(() => {
    if (suggestedItemDetails && suggestedItemDetails.length > 0) {
      sessionStorage.setItem(
        skin + "__storedOrderLevelSuggestedItems",
        JSON.stringify(suggestedItemDetails)
      );
    }
  }, [suggestedItemDetails]);
  return (
    <>
      <h4 className="orderLevelSuggestedItemHeading">
        {appLabels["order"]["we-think-you-may-like"]}
      </h4>
      {suggestedItemDetails && suggestedItemDetails.length > 0 && !loading ? (
        <div className="suggested-item-wrapper suggested-items-order-level-wrapper">
          <div className="suggested-items-list order-suggested-items-list-container">
            <ul className="menu__category-cards-grid suggestedItemOrderLevelGrid" style={{ maxHeight: deviceWidth > 660 ? (deviceHeight - 300): "auto" }}>
              {suggestedItemDetails &&
                Object.keys(suggestedItemDetails).map((key, index) => (
                  <SuggestedItemOrderLevelCard
                    key={`${suggestedItemDetails[key].id}-${index}`}
                    handleClick={updateActiveItem}
                    index={index}
                    isSuggestedItemCard={true}
                    itemData={{
                      ...suggestedItemDetails[key],
                      category: suggestedItemDetails[key].category,
                      subcategory: suggestedItemDetails[key].subcategory,
                      onlineQuantityLimit:
                        !!suggestedItemDetails[key]["online_qty_limit"] &&
                        suggestedItemDetails[key]["online_qty_limit"] !== "None"
                          ? suggestedItemDetails[key]["online_qty_limit"]
                          : null,
                    }}
                    setIsAnySuggestedItemAddedToCart={setIsAnySuggestedItemAddedToCart}
                    modifyPanelActive={
                      suggestedItemDetails[key].id === itemModifyPanelActive
                        ? itemModifyPanelActive
                        : null
                    }
                  />
                ))}
            </ul>
          </div>
        </div>
      ) : (
        <div style={{ height: 100 }}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};
