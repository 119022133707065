import React, { useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import localforage from "localforage";

/** UI Components */
import { ReactComponent as IconX } from "../icons/IconX.svg";
import OrderTypeContext from "../../OnlineOrdering/OrderTypeContext";

const XButton = (props) => {
  const { destination, appLabels, skin } = props;
  const { state } = useLocation();
  const history = useHistory();
  const orderTypeContext = useContext(OrderTypeContext);
  const activeOrderType = orderTypeContext.value;
  const {parentItem} = useParams()
  const handleGoBack = () => {
    if (skin) {
      /** If use closes the combo page, remove their selection from localfoage */
      localforage.removeItem(skin + "__itemSelectionInProgress");
      localforage.removeItem(skin + "__storeIdToBeFaved");
      sessionStorage.removeItem(skin + "__orderStoreState");
    }
    if (destination) {
      if (window.location.href.includes("suggestedItem") && state && state.from !== "customization-modal") {
        let savedItemData = JSON.parse(
          sessionStorage.getItem(skin + "__savedMenuItem-" + parentItem)
        );

        if (
          savedItemData &&
          savedItemData.source &&
          savedItemData.source === "order-level-suggested-items"
        ) {
          if (state.from === "order-level-suggested-items") {
            history.push({
              pathname: savedItemData.backDestination,
              state: { from: "order-level-suggested-items", hideCustomizationWindow: true, source:"order-suggested-item-card" }
            });
          } else {
            history.push({
              pathname: `/online-ordering/${activeOrderType === "dinein" ? "review-order" : "checkout"
                }`,
              state:
                state.source === "item-card"
                  ? { from: "suggested-item-edit-page" }
                  : { from: "suggested-item-edit-page" },
            });
          }
        } else if (
          savedItemData &&
          savedItemData.source &&
          savedItemData.source === "item-level-suggested"
        ) {
          history.push({
            pathname: savedItemData.parentCategoryLink,
            state: { from: "suggested-item-page", source: "" },
          });
        } else {
          history.push({
            pathname: destination,
            state: { from: "suggested-item-page", source: "" },
          });
        }
      } else if (state && state.isModifyingItem) {
        if (
          state.source === "order-level-suggested-items"  &&
          state.from === "customization-modal"
        ) {
          history.push({
            pathname: `/online-ordering/${
              activeOrderType === "dinein" ? "review-order" : "checkout"
            }`,
            state: { from: "suggested-item-edit-page", customizedItemId: state.itemId },
          });
        } else if (state.source === "order-suggested-item-card" && state.from === "customization-modal") {
          let savedItemData = JSON.parse(
            sessionStorage.getItem(skin + "__savedMenuItem-" + parentItem)
          );
          history.push({pathname: savedItemData.backDestination, state: {from: "order-level-suggested-items", source:"order-suggested-item-card"}})
        }
        else if (
          state.source === "item-level-suggested-item" &&
          state.from === "customization-modal"
        ) {
          let savedItemData = JSON.parse(
            sessionStorage.getItem(skin + "__savedMenuItem-" + parentItem)
          );
          if (savedItemData) {
            history.push({pathname: savedItemData.backDestination, state: {from: "suggested-item-page"}})
          } else {
            history.goBack();
          }
        } else if (state.from === "order-level-suggested-items" && state.source === "item-card") {
          history.goBack();
        } else {
          history.goBack();
        }
      } else if (state && !state.isModifyingItem) {
        if (state.from === "order-level-suggested-items") {
          history.push({
            pathname: `/online-ordering/${
              activeOrderType === "dinein" ? "review-order" : "checkout"
            }`,
            state: { from: "suggested-item-edit-page" },
          });
        } else if (
          state.from === "order-level-suggested-items" &&
          state.source === "customization-modal"
        ) {
          history.push({
            pathname: `/online-ordering/${
              activeOrderType === "dinein" ? "review-order" : "checkout"
            }`,
            state: { from: "suggested-item-edit-page", customizedItemId: state.itemId },
          });
        } else if (state && state.from === "suggested-item-page") {
          history.push({ pathname: destination, state: { from: "suggested-item-page" } });
        } else {
          history.push({
            pathname: `/online-ordering/${
              activeOrderType === "dinein" ? "review-order" : "checkout"
            }`,
            state: { from: "item-page" },
          });
        }
      } else {
        history.push({ pathname: destination, state: { from: "app-header-back-button" } });
      }
    } else {
      history.goBack();
    }
  };

  return (
    <button type="button" aria-label={appLabels["general"]["go-back"]} onClick={handleGoBack}>
      <IconX aria-hidden="true" />
    </button>
  );
};
export default XButton;
