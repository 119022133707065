import React, { useContext, useEffect, useState } from "react";
import localforage from "localforage";
import { useHistory } from "react-router";

//Custom Hooks
import useWindowSize from "../_common/hooks/useWindowSize";

//Contexts
import UserRoleContext from "../App/UserRoleContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import AppLabelsContext from "../App/AppLabelsContext";
import StoreContext from "../OnlineOrdering/StoreContext";
import OrderTypeContext from "../OnlineOrdering/OrderTypeContext";
import CartContext from "../OnlineOrdering/Cart/CartContext";
import { MenuContext } from "../OnlineOrdering/Menu/MenuContext";
import AppSettingsContext from "../App/AppSettingsContext";

//UI Components
import { UserGreetings } from "../Dashboard/DashboardHeader/UserGreetings";
import pickupIcon from "./assets/pickupIcon.png";
import deliveryIcon from "./assets/deliveryIcon.png";
import dineinIcon from "./assets/dineinIcon.png";
import inSeatServiceIcon from "./assets/inSeatServiceIcon.png";
import { DialogModal } from "../_common/DialogModal/DialogModal";

//Helpers
import { getOrderTypeImages } from "./helpers/getOrderTypeImages";
import { jsonCopy, getOrderTypeInOptionFormat } from "../_common/helpers";

//CSS
import "./OrderTypes.css";

export const OrderTypes = () => {
  const history = useHistory();
  const deviceWidth = useWindowSize().width;
  const userRoleContext = useContext(UserRoleContext);
  const userStatus = userRoleContext.status;

  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const vexilor = merchantConfigContext.vexilor;

  const appLabels = useContext(AppLabelsContext);

  const orderType = useContext(OrderTypeContext);
  const orderStore = useContext(StoreContext);
  const orderCart = useContext(CartContext);
  const orderMenu = useContext(MenuContext);
  const appSettings = useContext(AppSettingsContext);
  const relativePath = appSettings["relative-path"];

  const isDineinHidden = !!appSettings["hide-dinein"] && appSettings["hide-dinein"] === "yes";
  const isDeliveryHidden = !!appSettings["hide-delivery"] && appSettings["hide-delivery"] === "yes";

  const isLocalhost = process.env.NODE_ENV === "development";

  const isPayOnly = appSettings["is-pay-only"] === "yes";
  const isStadiumSchema = merchantConfigContext.merchant.I55 === "stadium";

  const [orderTypeImages, setOrderTypeImages] = useState(null);
  const setDefaultOrderTypeImages = () => {
    setOrderTypeImages({
      "pickup-icon": { "image-src": pickupIcon, "image-alt": "pickup" },
      "delivery-icon": {
        "image-src": isStadiumSchema ? inSeatServiceIcon : deliveryIcon,
        "image-alt": "delivery",
      },
      "dinein-icon": { "image-src": dineinIcon, "image-alt": "dinein" },
    });
  };

  useEffect(() => {
    getOrderTypeImages(relativePath).then((fetchedImagesData) => {
      if (fetchedImagesData) {
        if (fetchedImagesData === "no-images-set" || isLocalhost) {
          setDefaultOrderTypeImages();
        } else {
          const tempImages = jsonCopy(fetchedImagesData);
          Object.keys(tempImages).forEach((imageKey) => {
            const image = tempImages[imageKey];
            const imageCopy = jsonCopy(image);
            if (
              imageCopy["image-src"] === "" ||
              imageCopy["image-src"].includes("_blank") ||
              imageCopy["image-src"].split(".").length === 1
            ) {
              if (imageKey === "pickup-icon") {
                imageCopy["image-src"] = pickupIcon;
              } else if (imageKey === "delivery-icon") {
                imageCopy["image-src"] = deliveryIcon;
              } else if (imageKey === "dinein-icon") {
                imageCopy["image-src"] = dineinIcon;
              }
            }

            image["image-src"] = imageCopy["image-src"];
          });
          setOrderTypeImages(tempImages);
        }
      } else {
        //if no order-types key was set in online-ordering/images.json
        setDefaultOrderTypeImages();
      }
    });
  }, []);

  // Generate options based on merchant-level order types
  const [orderTypeOptions, setOrderTypeOptions] = useState(null);
  useEffect(() => {
    const options = [];
    const merchantOrderTypes = vexilor.I2.active_order_types.split(",");

    merchantOrderTypes.forEach((merchantOrderType) => {
      const option = getOrderTypeInOptionFormat(
        merchantOrderType,
        appLabels["order"],
        isStadiumSchema
      );
      if (
        !(option.value === "dinein" && isDineinHidden) &&
        !(option.value === "delivery" && isDeliveryHidden)
      ) {
        options.push(option);
      }
    });

    setOrderTypeOptions(options);
  }, []);

  const resetMenu = () => {
    localforage.removeItem(skin + "__storedMenu");
    orderMenu.updateMenu({
      ...orderMenu,
      apiData: null,
      activeTopCategory: null,
      activeSubcategory: null,
      activeItem: null,
      menuLevel: null,
    });
  };

  // On Order Type or Order Store change - reset order storage
  const clearOrderStorage = () => {
    localforage.removeItem(skin + "__activeOrderType");
    localforage.removeItem(skin + "__orderDate");
    localforage.removeItem(skin + "__orderTime");
    localforage.removeItem(skin + "__trueBusinessDate");

    localforage.removeItem(skin + "__storedMenu");

    localforage.removeItem(skin + "__storedPromo");
    localforage.removeItem(skin + "__storedRewards");
    localforage.removeItem(skin + "__storedRewardsDetails");
    localforage.removeItem(skin + "__activeOrderStoreTable");

    localforage.removeItem(skin + "__storedTip");

    localforage.removeItem(skin + "__storedPromoCode");
    orderCart.updateCart(null);
    localforage.removeItem(skin + "__userDeliveryAddress");
    localforage.removeItem(skin + "__cart");
    localforage.removeItem(skin + "__stadium-schema");
    orderStore.updateActiveOrderStore(null);
    orderStore.updateActiveOrderStoreTable(null);
    resetMenu();
  };

  const updateOrderType = (selectedOption, orderTypeChanged) => {
    orderType.update(selectedOption.value);
    if (selectedOption.value === "pickup") {
      localforage.removeItem(skin + "__stadium-schema");
    }

    if (orderTypeChanged) {
      orderStore.updateActiveOrderStore(null);
      orderStore.updateActiveOrderStoreTable(null);

      orderCart.updateCart(null);
      localforage
        .removeItem(skin + "__storedMenu")
        .then(function () {
          // Run this code once the key has been removed.
          resetMenu();
          if (selectedOption.value === "dinein") {
            history.push("/online-ordering/dinein");
          } else if (selectedOption.value === "delivery") {
            if (isStadiumSchema) {
              history.push("/online-ordering/seat-section");
            } else {
              history.push("/online-ordering/delivery-address");
            }
          } else {
            history.push("/online-ordering/order-store");
          }
        })
        .catch(function (err) {
          // This code runs if there were any errors
          console.log(err);
        });
    } else {
      if (selectedOption.value === "dinein") {
        history.push("/online-ordering/dinein");
      } else if (selectedOption.value === "delivery") {
        if (isStadiumSchema) {
          history.push("/online-ordering/seat-section");
        } else {
          history.push({
            pathname: "/online-ordering/delivery-address",
            state: {
              from: "order-type-screen",
            },
          });
        }
      } else {
        history.push("/online-ordering/order-store");
      }
    }
  };

  const [isShowingCartClearPopup, setIsShowingCartClearPopup] = useState(null);
  const [modalContent, setModalContent] = useState({});
  const handleSelectingOrderType = (selectedOrderType) => {
    const isCart = orderCart && orderCart.quantity > 0;
    localforage.getItem(skin + "__activeOrderType").then((storedOrderType) => {
      const orderTypeChanged = storedOrderType && storedOrderType !== selectedOrderType.value;
      if (isCart && orderTypeChanged) {
        setModalContent({
          content: (
            <div className="confirm-order-type__container">
              <p>
                {appLabels["order"]["order-type-change-confirmation"].split("[order-type]")[0]}
                <strong>{selectedOrderType.displayValue.toUpperCase()}</strong>
                {appLabels["order"]["order-type-change-confirmation"].split("[order-type]")[1]}
              </p>
              <p>{appLabels["order"]["your-current-order-will-be-cleared"]}</p>
            </div>
          ),
          onConfirm: () => {
            clearOrderStorage();
            orderCart.updateCart(null);
            updateOrderType(selectedOrderType, orderTypeChanged);
          },
        });
        setIsShowingCartClearPopup(true);
      } else {
        updateOrderType(selectedOrderType, orderTypeChanged);
      }
    });
  };

  /** if there is only one order type available, auto select it for the user to minimize the clicks required */
  useEffect(() => {
    if (orderTypeOptions && orderTypeOptions.length === 1) {
      updateOrderType(orderTypeOptions[0], false);
    }
  }, [orderTypeOptions]);

  return (
    <>
      {orderTypeOptions && orderTypeOptions.length > 1 && orderTypeImages && (
        <>
          {deviceWidth < 660 && (
            <UserGreetings
              skin={skin}
              isLoggedIn={userStatus === "logged-in"}
              hideExtraInformation={true}
            />
          )}
          <div className="order-types__buttons-container">
            <header className="dashboard__header">
              <h1>{appLabels["order"]["order-type-selection-blurb"]}</h1>
            </header>
            {orderTypeOptions.map((orderType, index) => (
              <React.Fragment key={index}>
                {((orderType.value === "dinein" && !isDineinHidden) ||
                  orderType.value !== "dinein") &&
                  ((isPayOnly && orderType.value !== "dinein") || !isPayOnly) && (
                    <div className="order-types__button-wrapper">
                      <button
                        type="button"
                        className="order-types__button"
                        onClick={() => handleSelectingOrderType(orderType)}
                      >
                        <div className="order-types__icon-wrapper">
                          {" "}
                          {orderType.value === "pickup" && (
                            <img
                              src={orderTypeImages["pickup-icon"]["image-src"]}
                              alt={orderTypeImages["pickup-icon"]["image-alt"]}
                              aria-hidden="true"
                            />
                          )}
                          {orderType.value === "delivery" && (
                            <img
                              src={orderTypeImages["delivery-icon"]["image-src"]}
                              alt={orderTypeImages["delivery-icon"]["image-alt"]}
                              aria-hidden="true"
                            />
                          )}
                          {orderType.value === "dinein" && (
                            <img
                              src={orderTypeImages["dinein-icon"]["image-src"]}
                              alt={orderTypeImages["dinein-icon"]["image-alt"]}
                              aria-hidden="true"
                            />
                          )}
                        </div>
                        <span>{orderType.displayValue}</span>
                      </button>
                    </div>
                  )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}

      {isShowingCartClearPopup && (
        <DialogModal
          isConfirmText={true}
          isCancelConfirm={true}
          hideConfirmButton={false}
          message={modalContent.content}
          resetRemoveDialog={() => {
            setIsShowingCartClearPopup(false);
          }}
          isHTMLContent={true}
          confirmAction={modalContent.onConfirm}
        />
      )}
    </>
  );
};
