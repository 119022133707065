// Libraries
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useWindowSize from "../../_common/hooks/useWindowSize";
import './giftCardDetails.css'
// Helper functions
import { onAddProductToGCCart } from "../../_common/helpers/dataLayerHelpers";
import { jsonCopy } from "../../_common/helpers";

// Contexts
import GCCartContext from "../GCCartContext";
import MerchantConfigContext from "../../App/MerchantConfigContext";
import AppLanguageContext from "../../App/AppLanguageContext";
import GCAvailableContext from "../GiftCardsAvailableContext";

// UI components
import { ReactComponent as IconPlus } from "../../_common/icons/IconPlus.svg";
import { ReactComponent as IconMinus } from "../../_common/icons/IconMinus.svg";
import AppLabelsContext from "../../App/AppLabelsContext";

//Assets
import { ReactComponent as IconX } from "../../_common/icons/IconX.svg";
import { AddGiftCardDetailsForm } from "./AddGiftCardDetailsForm";
import { FAB } from "../../_common/buttons/FAB/FAB";
import { Button } from "@material-ui/core";
import { useParams } from 'react-router';
import { callAPI } from "../../_common/Api";
import { GCOrderSettingsCart } from "../GCOrderSettingsCart";
import { LoadingSpinner } from "../../_common/LoadingSpinner";
import { DialogModal } from "../../_common/DialogModal/DialogModal";
import "../GiftCardCarousel.css"
import localforage from "localforage";
import BrandingContext from "../../App/BrandingContext";
import { getContrastColor } from "../../_common/colorHelpers";
import FocusTrap from "focus-trap-react";

/* Used to show form to enter recipient info for a selected gift card.
   Same component is shown for editing a gift card recipient from gift card cart */

export const AddGiftCardDetails = (props) => {
  const [giftCard, setGiftCard] = useState(null) //currently selected gift card
  const [closingAnimationClass, setClosingAnimationClass] = useState("");
  const [numberOfRecipients, setNumberOfRecipients] = useState(1) // initially form is diplayed to enter info for one reciepint so it is assumed number of recipient is 1 in the start
  const [recipientDetailsList, setRecipientDetailsList] = useState([{}]) // details of recipient added. This is set to array with an empty object as form is displayed for it on the start by default
  const [areRecipientsValid, setAreRecipientsValid] = useState(null) // used as a flag if any invalid recipient exists in recipientDetailsList
  const [editingItemFromCart, setEditingItemFromCart] = useState(null) // used as a flag to switch relevant form fields and button text to edit mode
  const [showConfirmExitDialog, setShowConfirmExitDialog] = useState(false) // used to show conformation dialog box when closing the modal without saving changes.
  const [triggerFormsSubmit, setTriggerFormsSubmit] = useState(false) // this sets in motion mass submission of all recipient forms and ensures they all are validated
  const [isEditingCartItem, setIsEditingCartItem] = useState(false) // used as flag if editing an item from gift cards cart. 
  const [formError, setFormError] = useState() // used to display relevant error message 
  const [formRecipientsAreValid, setFormRecipientsAreValid] = useState(false) // another flag that determines validation of each recipient form.
  const [editingItemFromCartKey, setEditingItemFromCartKey] = useState() // used to store key for gift card cart item that is being edited. Also used to compare context items for gift card cart and update relevant item.
  const [storedUserFirstName, setStoredUserFirstName] = useState(null)  // used to prefill sender name input inside Add gift card details form
  const [originalLocationPath, setOriginalLoctionPath] = useState(null) // used to keep track of current location and previous location path
  const [clearFormGiftCardAmount, setClearFormGiftCardAmount] = useState(false) // flag/prop used to indicate to child form components to clear gift card amount input field
  const [isAnyValuePresent, setIsAnyValuePresent] = useState(false) //used to keep track of any value present in the recipient form, this is used to show confirm exit dialog modal.

  const [numOfInvalid, setNumOfInvalid] = useState(0)
  const [addingToOrderLoading, setAddingToOrderLoading] = useState(false)

  const appLabels = useContext(AppLabelsContext)
  const cartContext = useContext(GCCartContext)
  const merchantConfigContext = useContext(MerchantConfigContext)
  const skin = merchantConfigContext.skin
  const branding = useContext(BrandingContext)
  const textIconColor = getContrastColor(branding["online-order-settings-background-colour"]);
  const cartItemsLimit = Number(merchantConfigContext.merchant.I4[3]) // AIV value CWS_ECERT_CUST

  const appLanguage = useContext(AppLanguageContext)
  const giftCardsListContext = useContext(GCAvailableContext)
  let cart = cartContext.value || [];
  const cartData = cartContext;
  const imagePlaceholder = require("../../_common/Images/EGiftCardImageUnavailable.png") // this image is used to show a gift card image when the one from API is missing.

  const deviceWidth = useWindowSize().width
  const HOSTNAME = window.location.hostname;
  const IS_LOCALHOST =
    HOSTNAME === "localhost" ||
    (HOSTNAME.split(".").length === 4 && isNaN(Number(HOSTNAME.split("."))));
  const baseURL = !IS_LOCALHOST ? window.origin : "https://alpha-wwws.givex.com"
  const history = useHistory()
  const { state } = useLocation()
  const location = useLocation()
  const isTimePickerEnabled = merchantConfigContext.merchant.I57 !== "" ? true : false
  const timerRef = useRef(null);

  let { giftCardId } = useParams();

  // call for API 1005
  const callApi1005 = async () => {
    await callAPI(skin, "dc_1005", {
      params: [
        appLanguage,
        "1005",
        "mqid",
        "mqpass",
        "consumer",
      ],
      id: "1005",
    }).then((data) => {

      if (data.result.I2) {
        let listOfGiftCards = []
        data.result.I2.forEach(gc => {
          //decoding the numbered array values to name value pairs
          let tempGiftCard = {
            id: gc[0],
            title: gc[1],
            reference: gc[2],
            giftCardType: gc[3],
            bundleSize: gc[4],
            denominationList: gc[5],
            denominationCustomizable: gc[6],
            cardFaceCustomizable: gc[7],
            orderLimit: gc[8],
            giftCardImageUrl: gc[9],
            cwsThemeId: gc[10],
            cwsThemeName: (gc[11] && gc[11] !== "") ? gc[11] : "Other",
            replacementItem: gc[12],
            language: gc[13],
            description: gc[14],
          }

          if (tempGiftCard.id === giftCardId) {
            setGiftCard(tempGiftCard)
          }
          listOfGiftCards.push(tempGiftCard)

        })

      }
    });
  }

  useEffect(() => {
    localforage.getItem(skin + "__gcCompletedOrderId").then((completedOrderId) => {
      if (completedOrderId) {
        localforage.removeItem(skin + "__gcCompletedOrderId")
      }
    })
    if (!isEditingCartItem || !editingItemFromCart) {
      localforage.getItem(skin + "__userFirstName").then((userFirstName) => {
        if (userFirstName) {
          setStoredUserFirstName(userFirstName)
        } else {
          setStoredUserFirstName("")
        }
      })
    }
  }, [])

  useEffect(() => {

    if (state && (state.isEditing || state.giftCard)) {
      setGiftCard(state.giftCard)
    }

    if (giftCardsListContext && giftCardsListContext.length > 0) {
      let listOfGiftCards = []
      giftCardsListContext.forEach(gc => {

        let tempGiftCard = {
          id: gc[0],
          title: gc[1],
          reference: gc[2],
          giftCardType: gc[3],
          bundleSize: gc[4],
          denominationList: gc[5],
          denominationCustomizable: gc[6],
          cardFaceCustomizable: gc[7],
          orderLimit: gc[8],
          giftCardImageUrl: gc[9],
          cwsThemeId: gc[10],
          cwsThemeName: gc[11],
          replacementItem: gc[12],
          language: gc[13],
          description: gc[14],
        }

        if (tempGiftCard.id === giftCardId) {
          setGiftCard(tempGiftCard)
        }
        listOfGiftCards.push(tempGiftCard)

      })
      let tmpCopy = []
      listOfGiftCards.forEach(gc => {
        tmpCopy.push(gc)
      })
    } else {
      callApi1005()
    }
    /*
    When editing a gift card cart item, get the editing item key and find the item in cart context.
    When item found, cast the info from cart context to recipient form format.
    */
    if (state && state.isEditing) {
      let cartItemKey = state.editingItemEntryKey
      setEditingItemFromCartKey(cartItemKey)
      let editItemFromCart = cartContext.value.find(i => i.id === cartItemKey)
      editItemFromCart.status = "valid"
      /* this is a case when modifying a cart item */
      let formattedItemForForm = {

        0: {
          "name": "eGiftCardAmount",
          "value": editItemFromCart.amount
        },
        1: {
          "name": "deliveryDateAndTime",
          "value": editItemFromCart.deliveryDateTime
        },
        2: {
          "name": "senderName",
          "value": editItemFromCart.senderName
        },
        3: {
          "name": "recipientEmail",
          "value": editItemFromCart.recipientEmail
        },
        4: {
          "name": "recipientName",
          "value": editItemFromCart.recipientName
        },
        5: {
          "name": "confirmRecipientEmail",
          "value": editItemFromCart.recipientEmail
        },
        "status": "valid"
      }
      if (editItemFromCart.message !== "") {
        formattedItemForForm["6"] = {
          "name": "greetings",
          "value": editItemFromCart.message
        }
      }

      setEditingItemFromCart(editItemFromCart)
      let recipientDetails = []
      recipientDetails.push(formattedItemForForm)
      setRecipientDetailsList(recipientDetails)
      setIsEditingCartItem(true)

    }

    if (cartContext.value && cartContext.value.length > 0 && (!state || !state.isEditing)) {
      if (cartItemsLimit !== "" && cartContext.value.length === cartItemsLimit) {
        setFormError(appLabels["gift-card"]["item-order-limit"] + " " + cartItemsLimit + " " + appLabels["gift-card"]["reached"])
        setAreRecipientsValid(false)
      }
    }
  }, [state])

  /*
  This useEffect clears the eGiftCard value when user goes from
  customizable gift card to denomination list one.
  It triggers clear form values and then clears the
  eGiftCardAmount input form data.
  */
  useEffect(() => {

    if (location && originalLocationPath === null) {
      setOriginalLoctionPath(location.pathname)
    } else
      if (location && originalLocationPath !== location.pathname) {
        if (giftCard.denominationCustomizable === "f") {
          setClearFormGiftCardAmount(true)
        }
        setOriginalLoctionPath(location.pathname)
      }

  }, [giftCard])

  /* 
  Updates/adds recipient data.
  Toggles the row for newaly added or updated recipient
  Updated recipientDetailsList with new/updated recipient with status of valid
  */
  function updateGiftCardDetails(data = null, isUpdatingRecipient = false) {
    data.id = numberOfRecipients
    let recipientRow = document.getElementById(data.recipientIndex + "-recipientRow")
    let rowHeading = recipientRow.getElementsByClassName("order-item__option-heading")[0].getElementsByTagName("span")[0]

    let recipientName = data.find((i) => i.name === "recipientName").value
    rowHeading.innerText = recipientName
    let recipientDetailsSection = document.getElementById(data.recipientIndex + "-recipientDetails")

    if (!recipientDetailsSection.classList.contains("visually-hidden"))
      toggleRow(data.recipientIndex)

    let recipientDetailsCopy = recipientDetailsList
    if (recipientDetailsList.length === 1) {
      data.status = "valid"
      recipientDetailsCopy[0] = data
    }
    else {
      data.status = "valid"
      recipientDetailsCopy[data.recipientIndex] = data
    }
    if (!isUpdatingRecipient && !triggerFormsSubmit) {
      recipientDetailsCopy.push({})
      setNumberOfRecipients(numberOfRecipients + 1)
    } else if (isUpdatingRecipient && !triggerFormsSubmit) {
      let emptyRowFound = false
      let emptyRowFoundIndex
      recipientDetailsList.forEach((r, index) => {
        if (!r.status) {
          emptyRowFound = true
          emptyRowFoundIndex = index
        } else {
          emptyRowFound = false
        }
      })
      if (!emptyRowFound) {
        recipientDetailsCopy.push({})
        setNumberOfRecipients(numberOfRecipients + 1)
      } else {
        toggleRow(emptyRowFoundIndex)
      }
    }

    setRecipientDetailsList(recipientDetailsCopy);

  }

  // show/hide a specific recipient row
  const toggleRow = (id, toggleOtherRows = false) => {
    let recipientDetailsContainer = document.getElementById(id + "-recipientDetails")
    //recipientDetailsList.classList.toggle("visually-hidden")
    recipientDetailsContainer.classList.toggle("slideDownAnimation")

    // update row header when a row is collpase/expanded with new values from form.
    recipientDetailsList.forEach((r, index) => {
      if (r.status !== "deleted") {
        let formElement = document.getElementById("form--gift-card-" + index)
        if (formElement) {
          let recipientRow = document.getElementById(index + "-recipientRow")
          let rowHeading = recipientRow.getElementsByClassName("order-item__option-heading")[0].getElementsByTagName("span")[0]
          rowHeading.innerText = formElement.querySelector("#input--recipientName").value === "" ? appLabels["account"]["order-details"] : formElement.querySelector("#input--recipientName").value
        }
      }
    })

    if (recipientDetailsContainer.classList.contains("visually-hidden") && toggleOtherRows) {
      recipientDetailsList.forEach((r, index) => {
        if (!r.status || r.status !== "deleted") {

          let otherRecipient = document.getElementById(index + "-recipientDetails")
          if (otherRecipient) {
            if (!otherRecipient.classList.contains("visually-hidden")) {
              otherRecipient.classList.add("visually-hidden")
            }
            document.getElementById(index + "recipientRowPlus").classList.remove("visually-hidden")
            document.getElementById(index + "recipientRowMinus").classList.add("visually-hidden")
          }
        }
      })

    }

    recipientDetailsContainer.classList.toggle("visually-hidden")
    if (recipientDetailsContainer.classList.contains("visually-hidden")) {
      document.getElementById(id + "recipientRowPlus").classList.remove("visually-hidden")
      document.getElementById(id + "recipientRowMinus").classList.add("visually-hidden")

    } else {
      document.getElementById(id + "recipientRowPlus").classList.add("visually-hidden")
      document.getElementById(id + "recipientRowMinus").classList.remove("visually-hidden")
    }

  }

  /*
  Accepts a recipient index and targets the row and details container of that recipient
  The recipient row and details are set to display none
  The recipientDetailsList is updated with that recipient status set to deleted
  */
  const removeRecipientRow = (e) => {

    document.getElementById(e + "-recipientRow").style.display = "none"
    document.getElementById(e + "-recipientDetails").style.display = "none"

    let recipientDetailsCopy = recipientDetailsList
    recipientDetailsCopy[e].status = "deleted"
    setRecipientDetailsList(recipientDetailsCopy)

    let validRecipients = recipientDetailsCopy.filter(r => r.status !== "deleted")
    let validRecipientsCount = validRecipients.length
    setNumberOfRecipients(validRecipientsCount)
    
  }

  /*
  Starts submission of all forms present in the component (equal to objects 
  in recipientDetailsList with status other than deleted).
  This also sets valid recipeints status to processing
  */
  const addToOrder = () => {

    if (!triggerFormsSubmit) {

      let updatedRecipientsStatuses = recipientDetailsList

      // in case when adding only one recipient, manually add status property to that object and continue
      if (recipientDetailsList.length === 1 && !recipientDetailsList[0].status) {

        updatedRecipientsStatuses[0].status = "valid"
      } 
      updatedRecipientsStatuses.forEach((item, index) => {
        if (item.status && item.status === "valid") {
          item.status = "processing"
          updatedRecipientsStatuses[index] = item
        }
      })
      setRecipientDetailsList(updatedRecipientsStatuses)
      setTriggerFormsSubmit(true)

    }


  };

  /*
  This useEffect is key to submission and evaluating validation state of all recipient forms.
  This is only executed when triggerFromsSubmit is true
  
  If all recipients are valid (that have status other than deleted), then process to update
  Gift Card Cart context starts.
  For each valid recipient, entry key (or id) is set to current timestamps + index, this avoid
  the possibility of multiple gift cards that may have same timestamps hence
  same entry key or ids.

  If any recipeint is stuck in status proecessing or has set formRecipeintAreValid to false,
  then error messages are set.
  */
  useEffect(() => {
    let invalidCount = 0
    if (!formRecipientsAreValid || !triggerFormsSubmit) {
      invalidCount = invalidCount + 1
      setNumOfInvalid(invalidCount)
    }
    if (recipientDetailsList && recipientDetailsList[0].status && triggerFormsSubmit) {
      setAddingToOrderLoading(true)
      let isAnyRecipientInvalid = null
      let isAnyRecipientProcessing = null;
      isAnyRecipientInvalid = recipientDetailsList.find(i => !i.status)
      isAnyRecipientProcessing = recipientDetailsList.find(i => (i.status === "processing"))

      if (!isAnyRecipientInvalid && !isAnyRecipientProcessing) {
        setTriggerFormsSubmit(false)
        const isDataValid = submitGiftCardDetails()
        let updatedCartItems = []
        if (isDataValid) {
          recipientDetailsList.forEach((recipientGiftCardDetail, index) => {

            if (recipientGiftCardDetail[4] && recipientGiftCardDetail.status && recipientGiftCardDetail.status === "valid") {
              let recipientDetails = {
                "amount": recipientGiftCardDetail[0].value,
                "deliveryDateTime": recipientGiftCardDetail[1].value,
                "recipientEmail": recipientGiftCardDetail[2].value,
                "confirmRecipientEmail": recipientGiftCardDetail[3].value,
                "recipientName": recipientGiftCardDetail[4].value,
                "senderName": recipientGiftCardDetail[5].value,
                "message": recipientGiftCardDetail[6] ? recipientGiftCardDetail[6].value : "",
              }
              if (!isTimePickerEnabled) {
                let newDate = new Date(recipientDetails.deliveryDateTime)
                newDate.setHours(23)
                newDate.setMinutes(59)
                newDate.setSeconds(59)
                recipientDetails.deliveryDateTime = newDate.toString()
              }
              let category = giftCard.title
              const cartItem = JSON.parse(JSON.stringify(recipientDetails));
              cartItem.quantity = 1;
              cartItem.giftCard = giftCard

              const newEntryKeyValue = Date.now() + index;
              cartItem.entryKey = newEntryKeyValue;
              cartItem.id = newEntryKeyValue;

              /** Check if the same item with the same selection exist in the cart
               * by creating copies of the cart and the item being added
               * and removing unnecessary keys
               * and then comparing them as strings
               */

              const cartCopy = jsonCopy(cart);

              let matchFound = false;
              let matchIndex = 0;

              if (isEditingCartItem) {
                cartCopy.forEach((cartCopyItem, index) => {

                  if (cartCopyItem.id === editingItemFromCartKey) {
                    matchFound = true;
                    matchIndex = index;
                    cartItem.quantity = 0 //quantity + storedQuantity;
                    cartItem.entryKey = newEntryKeyValue;
                  }

                });
                if (matchFound) {
                  cartCopy[matchIndex] = cartItem
                  updatedCartItems = cartCopy
                  cart = cartContext.value
                }
              } else {

                if (cartContext.value) {
                  updatedCartItems = cartContext.value
                } else {
                  updatedCartItems = updatedCartItems.length > 0 ? updatedCartItems : []
                }
                updatedCartItems.push(cartItem);

                onAddProductToGCCart(cartItem, category, null);

                cart = cartContext.value && cartContext.value.length > 0 ? cartContext.value : updatedCartItems
              }

            }
          })
          cartContext.updateGCCart(updatedCartItems);
          timerRef.current = setTimeout(() => {
            if (isEditingCartItem) {
              history.push({
                pathname: `/gift-card/checkout/`,
                state: { from: "item-page" },
              });
            }
            else {
              history.push({
                pathname: `/gift-card/purchase/`,
                state: { from: "item-page" },
              });
            }
          }, 1000);


        }
      }
      else if (!isAnyRecipientProcessing || !formRecipientsAreValid) {
        setTriggerFormsSubmit(false)
        if (formRecipientsAreValid) {
          setAreRecipientsValid(false)
          setFormError(appLabels["gift-card"]["submit-recipient-error"])

        } else if (!formRecipientsAreValid) {
          setAreRecipientsValid(false)
          setFormError(appLabels["gift-card"]["submit-recipient-error"])
        }
      } else if (numOfInvalid > 0) {
        setNumOfInvalid(0)
        invalidCount = 0
        setTriggerFormsSubmit(false)
        setAreRecipientsValid(false)
        setFormError(appLabels["gift-card"]["submit-recipient-error"])

      }
      setAddingToOrderLoading(false)
    }
  }, [triggerFormsSubmit, formRecipientsAreValid])

  /* 
   Check for each recipient to have no empty value and there is atleast 1 valid recipient
  */
  const submitGiftCardDetails = () => {
    let recipientsValid = true
    if (recipientDetailsList.length > 0 && recipientDetailsList[0][4]) {
      let invalidRecipients = []
      recipientDetailsList.forEach(r => {
        for (let key in r) {
          if (r[key] === "" || r[key] === null) {
            recipientsValid = false
          }
        }
        if (!recipientsValid) {
          invalidRecipients.push(r)
        }
      })
      if (!recipientsValid) {
        return false
      } else {
        return true
      }
    } else {
      recipientsValid = false
      return false
    }
  }

  const closeGiftCardDetail = () => {
    setClosingAnimationClass(" menu-item-detail--closing");
    setTimeout(() => {
      if (state && state.from && state.from === "gift-card-checkout-page") {
        history.push({
          pathname: `/gift-card/checkout`,
        });
      } else {
        history.push({
          pathname: `/gift-card/purchase`,
          state: { from: "gift-card-item-page" },
        });
      }
    }, 300);

  }
  const resetInvalidForm = () => {
    setFormError(null)
    setAreRecipientsValid(null)
  };

  return (
    <FocusTrap active={deviceWidth >= 660 ? true : false}>
      {giftCard ? (
        <div
          id="giftCardDetailContainer"
          className={`item-details-main__Wrapper ${closingAnimationClass}`}>
          {deviceWidth >= 660 && (
            <div className="item-details__desktop-close-button-container">
              <button
                type="button"
                onClick={() =>
                  isAnyValuePresent || numberOfRecipients > 1
                    ? setShowConfirmExitDialog(true)
                    : closeGiftCardDetail()
                }
                aria-label={giftCard.title}>
                <IconX aria-hidden="true" />
              </button>
            </div>
          )}

          <div
            className={`item-detail__content isMaximized isExpanded giftCardDetailsWrapper`}
            id="top-hidden-item-name">
            <div
              className={` item_detail__top-content flex__row space-between wizard giftCardSelectHeader`}>
              <>
                <div className="flex__column item_detail__secondary-info_wrapper space-between expanded">
                  <div className="flex__row item-detail__image-container">
                    <div className={`giftCardImageWrapper  flex__column wizard expanded`}>
                      <div className="item-detail__image-overlap-container giftCardImage selectedCardImage">
                        <img
                          src={
                            giftCard.giftCardImageUrl !== "" && giftCard.giftCardImageUrl
                              ? baseURL + giftCard.giftCardImageUrl
                              : imagePlaceholder
                          }
                          alt={giftCard.title}
                          className="item-detail__image item-detail__image-underlay"
                        />
                        {!giftCard.giftCardImageUrl && giftCard.giftCardImageUrl === "" && (
                          <div
                            alt={giftCard.title}
                            className={`dashboard__featured-item-img giftCard_slide giftCardInvalidImageSlide `}>
                            <p>
                              {appLabels["gift-card"]["gift-card-image-unavailable"]}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={`flex__column item_details__name-price-container  `}>
                      <h2 className="item-detail__heading wizard gcSelectedTitle">{giftCard.title}</h2>
                    </div>
                  </div>

                </div>
              </>
            </div>
            {recipientDetailsList ? (
              recipientDetailsList &&
              recipientDetailsList.length > 0 &&
              storedUserFirstName !== null &&
              recipientDetailsList.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className={`order-item__option-header space-between-wrapper space-between-wrapper--center-aligned giftCardRecipientRowHeader ${numberOfRecipients === 1 && recipientDetailsList[index].status !== "valid" && !isEditingCartItem ? "gcErrorHeaderRow" : ""} `}
                      id={index + "-recipientRow"}>
                      <div className="heading-with-icon">
                        <IconMinus
                          id={index + "recipientRowMinus"}
                          className={`icon--minus item-detail__option-note`}
                          aria-label={"collapse-section"}
                        />

                        <IconPlus
                          id={index + "recipientRowPlus"}
                          className={`icon--plus item-detail__option-note visually-hidden`}
                          aria-label={"expand-section"}
                        />

                        <h3 className="order-item__option-heading heading-with-icon ">
                          <span>
                            {index === 0 && editingItemFromCart
                              ? editingItemFromCart.recipientName
                              : appLabels["account"]["order-details"]}
                          </span>
                        </h3>
                      </div>
                     
                      <div className="recipientRightColumn" >
                      {numberOfRecipients > 1 && recipientDetailsList[index].status &&
                        <div className="item-detail__option-text-container">
                          <div className="item-detail__option-note item-detail__option-note--mandatory gcRemoveButton">
                            <Button
                              className="mui_button_override"
                              onClick={(event) => {
                                event.stopPropagation();
                                removeRecipientRow(index);
                              }}
                              tabIndex={2}>
                              {" "}
                              {appLabels["general"]["remove"]}{" "}
                            </Button>
                          </div>
                        </div> }
                     
                      {numberOfRecipients > 1 && !recipientDetailsList[index].status && (
                        <div className="item-detail__option-text-container">
                          <div className="item-detail__option-note item-detail__option-note--mandatory gcRemoveButton">
                            <Button
                              className="mui_button_override"
                              onClick={(event) => {
                                event.stopPropagation();
                                removeRecipientRow(index);
                              }}
                              tabIndex={2}>
                              {" "}
                              {appLabels["general"]["remove"]}{" "}
                            </Button>
                          </div>
                        </div>
                      )}
                      </div>
                      <button
                        className="item-detail__form__fieldset-legend-button"
                        id={index + "-recipientRowButton"}
                        type="button"
                        onClick={(event) => toggleRow(index, true)}
                        aria-label={"Expand/collapse"}></button>
                    </div>
                    <div
                      id={index + "-recipientDetails"}
                      className="giftCardRecipientDetailsContainer slideDownAnimation">
                      {index === 0 && editingItemFromCart ? (
                        <>
                          <AddGiftCardDetailsForm
                            isCustomDenomination={
                              giftCard.denominationCustomizable === "t" ? true : false
                            }
                            denominationList={
                              giftCard.denominationList[0] === "-1"
                                ? null
                                : giftCard.denominationList
                            }
                            updateGiftCardDetails={updateGiftCardDetails}
                            recipientIndex={index}
                            recipientStatus={e.status}
                            cartItemDetails={editingItemFromCart}
                            isEditingRecipient={true}
                            isEditingCartItem={isEditingCartItem}
                            triggerFormsSubmit={triggerFormsSubmit && recipientDetailsList[index]}
                            setTriggerFormsSubmit={setTriggerFormsSubmit}
                            setFormRecipientsAreValid={setFormRecipientsAreValid}
                            appLabels={appLabels}
                            skin={skin}
                            setIsAnyValuePresent={setIsAnyValuePresent}
                          />
                        </>
                      ) : (
                        <AddGiftCardDetailsForm
                          isCustomDenomination={
                            giftCard.denominationCustomizable === "t" ? true : false
                          }
                          denominationList={
                            giftCard.denominationList[0] === "-1" ? null : giftCard.denominationList
                          }
                          updateGiftCardDetails={updateGiftCardDetails}
                          recipientIndex={index}
                          isEditingRecipient={
                            recipientDetailsList[index] &&
                              recipientDetailsList[index][4] &&
                              recipientDetailsList[index][4].name &&
                              recipientDetailsList[index][4].name !== ""
                              ? true
                              : false
                          }
                            triggerFormsSubmit={triggerFormsSubmit && recipientDetailsList[index]}
                          setTriggerFormsSubmit={setTriggerFormsSubmit}
                          setFormRecipientsAreValid={setFormRecipientsAreValid}
                          recipientStatus={e.status}
                          appLabels={appLabels}
                          skin={skin}
                          storedUserFirstName={storedUserFirstName}
                          clearFormGiftCardAmount={clearFormGiftCardAmount}
                            setClearFormGiftCardAmount={setClearFormGiftCardAmount}
                            setIsAnyValuePresent={setIsAnyValuePresent}
                            numberOfRecipients={numberOfRecipients}  
                        />
                      )}
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
                <LoadingSpinner />
            )}
            {addingToOrderLoading &&
              <div className="giftCardFormsLoadingOverlay">
                <LoadingSpinner />
              </div>
            }
          </div>

          <div
            className="desktop-next-group-button__container"
            id="desktop-next-group-button__container">
            <FAB
              isAddItemType={true}
              isResetEnabled={cartItemsLimit !== "" && cartContext.value && cartItemsLimit === cartContext.value.length ? false : true}
              isFormValid={areRecipientsValid}
              buttonText={editingItemFromCart
                  ? appLabels["order"]["save-changes"]
                : appLabels["order"]["add-to-order"].replace(
                  "[item-quantity]",
                  numberOfRecipients
                )
              }
              buttonSuccessText={appLabels["general"]["done"]}
              formError={formError ? formError : null}
              onSubmit={() => addToOrder()}
              isLeftAligned={false}
              isBackgroundGradient={true}
              isDisabled={cartItemsLimit !== "" && cartContext.value && cartItemsLimit === cartContext.value.length && !isEditingCartItem ? true : false}

              FABCustomClass={`FAB-wrapper__item-details-page FAB-wrapper__combo-add-to-choice wizard gcFormButton ${cartData.quantity > 0 ? "gcFormButtonWithCartIcon" : ""} ` }
              customStyle={{
                border: `2px solid ${textIconColor}`,
                backgroundColor: "transparent",
                color: textIconColor,
                opacity: cartItemsLimit !== "" && cartContext.value && cartItemsLimit === cartContext.value.length && !isEditingCartItem ? 0.5 : 1,
                position: "sticky",
              }}
              isRelativePositioning={true}
              relativeContainerId={"desktop-next-group-button__container"}
              cartButton={
                cartData.quantity > 0 ? (
                  <GCOrderSettingsCart
                    customClass={deviceWidth <= 660 ? "inlineFormButton" : ""}
                    isCheckoutScreen={false}
                  />
                ) : null
              }
              resetInvalidForm={resetInvalidForm}
            />
          </div>

          {showConfirmExitDialog && (
            <DialogModal
              message={
                <div>
                  <b> {appLabels["gift-card"]["sure-text"]}</b>
                  <br /> <br /> {appLabels["gift-card"]["exit-gift-card-dialog-message"]}
                </div>
              }
              resetRemoveDialog={() => setShowConfirmExitDialog(false)}
              isCancelConfirm={true}
              confirmAction={() => closeGiftCardDetail()}
              isHTMLContent={true}
            />
          )}


        </div>
      ) : (
        <LoadingSpinner />
      )}
    </FocusTrap>
  );
}
