import React, { useState, useEffect, useRef } from "react";
import { checkNumberOfSelectedItemsInOptionsGroup } from "../../helpers/getMenuData";
import { SingleChoiceAddonModifier } from "./SingleChoiceAddonModifier";
import { MultipleChoiceAddonModifier } from "./MultipleChoiceAddonModifier";
import "./AddonOrModifierGroupItems.css";
import "../../../../_common/Form/Form.css";
import { useLocation } from 'react-router-dom';
export const AddonOrModifierGroupItems = (props) => {
  const {
    topLevelOptionGroup,
    updateConfirmedOptions,
    instructionsText,
    mandatoryOptionClass,
    isInCart,
    isBeingSelected,
    comboChildItem,
    isTheSameChildItem,
    addonModifierGroupExpanded,
    addonsOutOfStock,
    displayType,
    updateActiveOptionGroupIndex,
    updateOrderingState,
    orderingState,
    isStepByStepOrderingEnabled,
    isEditingComboItem,
    cartItem,
    comboCartItem,
  } = props;

  const [goToNextGroup, setGoToNextGroup] = useState(false);

  useEffect(() => {
    if (goToNextGroup) {
      setTimeout(() => {
        const go = async () => {
          await updateActiveOptionGroupIndex();
        };
        go();
      }, 90);
    }
  }, [goToNextGroup]);

  // Determines whether to render radio buttons or checkboxes
  const isSingleChoiceOption =
    parseInt(topLevelOptionGroup.maxQuantity) === 1 &&
    parseInt(topLevelOptionGroup.minQuantity) === 1;
  
  const { state } = useLocation()

  /** If item is in cart, update the selectedOptions object with already selected items */
  useEffect(() => {
    //  check here to disable defauls by setting isInCart to true for suggeste items
    if ((topLevelOptionGroup && isInCart) || (state && state.from === "suggested-item-page")) {
      let tempSelectedOptions = {};
      const tempSelectedOptionsItems = topLevelOptionGroup.items;

      Object.keys(tempSelectedOptionsItems).forEach((itemKey) => {
        if (tempSelectedOptionsItems[itemKey].isSelected) {
          tempSelectedOptions[itemKey] = tempSelectedOptionsItems[itemKey];
        }
      });

      if (!isSingleChoiceOption) {
        updateSelectedMultipleOptions(tempSelectedOptions);
      }
    }
  }, [isInCart]);

  const [selectedSingleOption, setSelectedSingleOption] = useState(null);
  const updateSelectedOption = (selectedOption) => {
    setSelectedSingleOption(selectedOption);
  };

  const [selectedMultipleOptions, setSelectedMultipleOptions] = useState(null);
  const updateSelectedMultipleOptions = (options) => {
    setSelectedMultipleOptions(options);
  };

  const [unselectedMultipleOptions, setUnselectedMultipleOptions] = useState(null);
  const updateUnselectedMultipleOptions = (options) => {
    setUnselectedMultipleOptions(options);
  };

  const [topLevelOptionGroupCopy, setTopLevelOptionGroupCopy] = useState(null);
  const jsonCopy = (src) => {
    return JSON.parse(JSON.stringify(src));
  };

  useEffect(() => {
    //create a copy of the top level options group
    setTimeout(() => {
      const tempCopy = jsonCopy(topLevelOptionGroup);
      setTopLevelOptionGroupCopy(tempCopy);
    }, 90);
  }, [topLevelOptionGroup]);

  const isCheckingAutoSelection = useRef();

  // Check if non-standalone addon is default and preselect it
  useEffect(() => {
    const items = topLevelOptionGroup.items;

    if (isCheckingAutoSelection.current === true) {
      return;
    }
    isCheckingAutoSelection.current = true;
    if (((!isEditingComboItem && !isInCart) || (!!comboChildItem && !isTheSameChildItem)) && !(state && (state.source === "order-suggested-item-card" || state.source === "item-level-suggested" || state.from === "suggested-item-page"))) {
      //if there are no previously selected options then look for the default values in the addon group
      if (
        Object.keys(items).length > 0 &&
        checkNumberOfSelectedItemsInOptionsGroup(topLevelOptionGroup) === 0
      ) {
        let autoSelectedDefaults = {};

        for (let key in items) {
          if (
            items[key].isDefault === "True" &&
            (items[key].item_count === "None" ||
              Number(items[key].item_count) > 0 ||
              !items[key].hasOwnProperty("item_count"))
            
          ) {
            if (isSingleChoiceOption) {
              autoSelectedDefaults = items[key];
              autoSelectedDefaults.isSelected = true;
              autoSelectedDefaults.isFirstInstanceDefault = true;

              const hasNestedModifiers =
                items[key].modifierGroups && Object.keys(items[key].modifierGroups).length > 0;

              /** Once the default addon is selected, check to see if it has modfier groups
               * If any of the nested modifier group items is set to Default mark them as selected
               */
              if (hasNestedModifiers) {
                const nestedModifiers = items[key].modifierGroups;
                const nesterModifierKeys = Object.keys(nestedModifiers);

                for (let nestedModGroupKey in nesterModifierKeys) {
                  const nestedItems =
                    items[key].modifierGroups[nesterModifierKeys[nestedModGroupKey]].items;
                  const nestedItemKeys = Object.keys(nestedItems);

                  for (let nestedModItemKey in nestedItemKeys) {
                    if (nestedItems[nestedItemKeys[nestedModItemKey]].isDefault === "True") {
                      autoSelectedDefaults.modifierGroups[
                        `addonModifierGroup-${
                          items[key].modifierGroups[nesterModifierKeys[nestedModGroupKey]].id
                        }`
                      ].items[`modifier-${nestedItems[nestedItemKeys[nestedModItemKey]].id}`] =
                        nestedItems[nestedItemKeys[nestedModItemKey]];

                      autoSelectedDefaults.modifierGroups[
                        `addonModifierGroup-${
                          items[key].modifierGroups[nesterModifierKeys[nestedModGroupKey]].id
                        }`
                      ].items[
                        `modifier-${nestedItems[nestedItemKeys[nestedModItemKey]].id}`
                      ].isSelected = true;
                    }
                  }
                }
              }

              break;
            } else {
              const type = items[key].type === "modifier" ? "modifier" : "addon";
              autoSelectedDefaults[`${type}-${items[key].id}`] = items[key];
              autoSelectedDefaults[`${type}-${items[key].id}`].isSelected = true;

              //if it is a stand alone addon and it is being auto selected, set its quantity to 1
              if (type === "addon") {
                autoSelectedDefaults[`${type}-${items[key].id}`].quantity = 1;
              }

              const hasNestedModifiers =
                items[key].modifierGroups && Object.keys(items[key].modifierGroups).length > 0;

              /** Once the default addon is selected, check to see if it has modfier groups
               * If any of the nested modifier group items is set to Default mark them as selected
               */
              if (hasNestedModifiers) {
                const nestedModifiers = items[key].modifierGroups;
                const nesterModifierKeys = Object.keys(nestedModifiers);

                //nesterModifierKeys.forEach((nestedModGroupKey) => {
                for (let nestedModGroupKey in nesterModifierKeys) {
                  const nestedItems =
                    items[key].modifierGroups[nesterModifierKeys[nestedModGroupKey]].items;
                  const nestedItemKeys = Object.keys(nestedItems);

                  //Object.keys(nestedItems).forEach((nestedModItemKey) => {
                  for (let nestedModItemKey in nestedItemKeys) {
                    if (nestedItems[nestedItemKeys[nestedModItemKey]].isDefault === "True") {
                      autoSelectedDefaults[`addon-${items[key].id}`].modifierGroups[
                        `addonModifierGroup-${
                          items[key].modifierGroups[nesterModifierKeys[nestedModGroupKey]].id
                        }`
                      ].items[`modifier-${nestedItems[nestedItemKeys[nestedModItemKey]].id}`] =
                        nestedItems[nestedItemKeys[nestedModItemKey]];

                      autoSelectedDefaults[`addon-${items[key].id}`].modifierGroups[
                        `addonModifierGroup-${
                          items[key].modifierGroups[nesterModifierKeys[nestedModGroupKey]].id
                        }`
                      ].items[
                        `modifier-${nestedItems[nestedItemKeys[nestedModItemKey]].id}`
                      ].isSelected = true;
                    }
                  }
                }
              }
            }
          }
        }

        if (Object.keys(autoSelectedDefaults).length > 0) {
          setTimeout(() => {
            if (isSingleChoiceOption) {
              updateSelectedOption(autoSelectedDefaults);
            } else {
              updateSelectedMultipleOptions(autoSelectedDefaults);
            }
          }, 250);
        }
      } else {
        // else if there is at least previousely selected option set their 'isSelected' flag to true
        let autoSelectedDefaults = {};
        for (let key in items) {
          if (items[key].isSelected) {
            if (!items[key].isStandalone) {
              if (isSingleChoiceOption) {
                autoSelectedDefaults = items[key];
                autoSelectedDefaults.isSelected = true;
                autoSelectedDefaults.isFirstInstanceDefault = true;
                break;
              } else {
                if (items[key].type === "modifier") {
                  autoSelectedDefaults[`modifier-${items[key].id}`] = items[key];
                  autoSelectedDefaults[`modifier-${items[key].id}`].isSelected = true;
                } else if (items[key].type === "addOn") {
                  autoSelectedDefaults[`addon-${items[key].id}`] = items[key];
                  autoSelectedDefaults[`addon-${items[key].id}`].isSelected = true;
                }
              }
            }
          }
        }

        if (Object.keys(autoSelectedDefaults).length > 0) {
          setTimeout(() => {
            if (isSingleChoiceOption) updateSelectedOption(autoSelectedDefaults);
            else updateSelectedMultipleOptions(autoSelectedDefaults);
          }, 250);
        }
      }
    } else if (isInCart) {
      /** This check will ensure that the correct selected info is sent to nested addon/mods component */
      let previousSelection = {};
      Object.keys(items).forEach((optionGroupKey) => {
        if (items[optionGroupKey].isSelected) {
          previousSelection[optionGroupKey] = items[optionGroupKey];
          if (optionGroupKey.includes("addon")) {
            previousSelection[optionGroupKey].quantity =
              previousSelection[optionGroupKey].quantity || 1;
          }
          delete previousSelection[optionGroupKey].isFirstInstanceDefault;
        }
      });

      if (Object.keys(previousSelection).length > 0) {
        setTimeout(() => {
          if (isSingleChoiceOption) {
            updateSelectedOption(previousSelection);
          } else {
            updateSelectedMultipleOptions(previousSelection);
          }
        }, 250);
      }
    }
  }, [isInCart, isTheSameChildItem, comboChildItem]);

  useEffect(() => {
    if (selectedSingleOption && Object.keys(selectedSingleOption).length > 0) {
      updateConfirmedOptions(selectedSingleOption);
    }

    if (selectedMultipleOptions && Object.keys(selectedMultipleOptions).length > 0) {
      updateConfirmedOptions(selectedMultipleOptions, false, unselectedMultipleOptions);
    } else if (
      selectedMultipleOptions &&
      Object.keys(selectedMultipleOptions).length === 0 &&
      unselectedMultipleOptions
    ) {
      updateConfirmedOptions(unselectedMultipleOptions, true);
    }
  }, [selectedSingleOption, selectedMultipleOptions, unselectedMultipleOptions]);

  return (
    !!topLevelOptionGroupCopy && (
      <div
        className={`order-item__options-form ${
          addonModifierGroupExpanded ? "" : " visually-hidden"
        }`}
      >
        <form
          id={"addons-modifiers-form" + topLevelOptionGroupCopy.id}
          className="form addons-modifiers-form"
        >
          {isSingleChoiceOption ? (
            <SingleChoiceAddonModifier
              topLevelOptionGroup={topLevelOptionGroup}
              topLevelOptionGroupCopy={topLevelOptionGroupCopy}
              selectedOption={selectedSingleOption}
              updateSelectedOption={updateSelectedOption}
              instructionsText={instructionsText}
              mandatoryOptionClass={mandatoryOptionClass}
              isInCart={isInCart}
              isBeingSelected={isBeingSelected}
              isTheSameChildItem={isTheSameChildItem}
              comboChildItem={comboChildItem}
              updateOrderingState={updateOrderingState}
              orderingState={orderingState}
              setGoToNextGroup={setGoToNextGroup}
              isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
              isEditingComboItem={isEditingComboItem}
              displayType={displayType}
              cartItem={cartItem}
              comboCartItem={comboCartItem}
            />
          ) : (
            <MultipleChoiceAddonModifier
              topLevelOptionGroup={topLevelOptionGroup}
              selectedOptions={selectedMultipleOptions}
              updateSelectedOptions={updateSelectedMultipleOptions}
              instructionsText={instructionsText}
              mandatoryOptionClass={mandatoryOptionClass}
              isInCart={isInCart}
              isBeingSelected={isBeingSelected}
              topLevelOptionGroupCopy={topLevelOptionGroupCopy}
              unselectedMultipleOptions={unselectedMultipleOptions}
              updateUnselectedMultipleOptions={updateUnselectedMultipleOptions}
              addonsOutOfStock={addonsOutOfStock}
              comboChildItem={comboChildItem}
              isTheSameChildItem={isTheSameChildItem}
              displayType={displayType}
              setGoToNextGroup={setGoToNextGroup}
              updateOrderingState={updateOrderingState}
              orderingState={orderingState}
              isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
              isEditingComboItem={isEditingComboItem}
              cartItem={cartItem}
              comboCartItem={comboCartItem}
            />
          )}
        </form>
      </div>
    )
  );
};
