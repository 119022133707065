import React, { useContext, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

/** Contexts */
import CartContext from "../../../Cart/CartContext";
import AppLabelsContext from "../../../../App/AppLabelsContext";
import { MenuContext } from "../../MenuContext";
import BrandingContext from "../../../../App/BrandingContext";
import MerchantConfigContext from "../../../../App/MerchantConfigContext";

/** Helpers */
import {
  addComboChildToCombo,
  addMenuItemToCart,
  getCurrentItemQuantityInCart,
  getTotalComboChildQuantity,
  removeAllInstanceOfItemFromCart,
  removeComboChildFromCombo,
  removeItemsFromArray,
  removeMenuItemFromCart,
  updateComboChildItemQuantity,
} from "../../helpers/orderingHelpers";
import { onProductClick } from "../../../../_common/helpers/dataLayerHelpers";
import {
  onAddSuggestedItemXtreme,
  onProductClickXtreme,
  onRemoveProductFromCartXtreme,
} from "../../../../_common/helpers/xtremePushHelper";

import { getContrastColor } from "../../../../_common/colorHelpers";
import { jsonCopy } from "../../../../_common/helpers";

/** UI Components */
import { DialogModal } from "../../../../_common/DialogModal/DialogModal";
import QuickAddCustomizationModal from "./QuickAddCustomizationModal";

/** Assets */
import { IconEdit } from "../../../../_common/icons";
import { ReactComponent as IconPlus } from "../../../../_common/icons/IconPlus.svg";
import { ReactComponent as IconMinus } from "../../../../_common/icons/IconMinus.svg";

/** CSS */
import "./MenuItemQuickAddButton.css";

const MenuItemQuickAddButton = (props) => {
  const {
    itemData,
    requiresCustomization,
    comboCartItem,
    updateActiveItem,
    subGroupQuantityReached,
    isModifyDisplay,
    setValidateGoingToNextLevel,
    setIsEditingComboItem,
    isQuantityCombo,
    onlineQuantityLimit,
    preSelectedItem,
    hasNoAddonModifier,
    triggerSource = null,
    setUpdateActiveSuggestedItem = null,
    isSuggestedItemCard = false
  } = props;

  const history = useHistory();

  const cartContext = useContext(CartContext);
  const appLabels = useContext(AppLabelsContext);
  const orderMenu = useContext(MenuContext);
  const branding = useContext(BrandingContext);
  const foodMenu = useContext(MenuContext);
  const skin = useContext(MerchantConfigContext).skin;

  const isComboChildItem = !!itemData["group_id"];
  const itemCount = itemData["item_count"];
  const itemNameBackgroundColor = branding["menu-card-item-name-background"];
  const menuLevel = orderMenu.menuLevel;

  const relatedComboChildItem = isComboChildItem
    ? comboCartItem.combo_child_items.filter(
        (item) => item.id === itemData.id && item.group_id === itemData.group_id
      )
    : [];
  //const maxComboGroupQuantity = Number(itemData["groupMinQty"]);
  const primaryColor = branding["primary-colour"];
  const secondaryColor = branding["secondary-colour"];
  const editIconColor = getContrastColor(secondaryColor);
  const cart = cartContext.value || [];
  let buttonTimerRef = useRef(null);
  let btnPlusRef = useRef();
  let btnQuickAddRef = useRef();

  const [areOptionsValid, setAreOptionsValid] = useState(null);
  const [isQuantityValid, setIsQuantityValid] = useState(null);
  const [showCustomizationPopup, setShowCustomizationPopup] = useState(false); //flag to toggle customization popup
  const [isRemovingAllItems, setIsRemovingAllItems] = useState(false); //flag to toggle confirmation popup for removing all instances of an item
  const [isRemovingItem, setIsRemovingItem] = useState(false); //flag to determine if an item is being removed from the selection
  const [itemQuantity, setItemQuantity] = useState(
    isComboChildItem
      ? !!relatedComboChildItem.length
        ? getTotalComboChildQuantity(relatedComboChildItem)
        : 0
      : getCurrentItemQuantityInCart(itemData, cart)
  );

  /**
   *
   * @param {Boolean} adding flag to determine if the quantity is being increased or decreased
   */
  const updateItemQuantity = (adding) => {
    let currentQuantity = itemQuantity || 0;

    if (adding) {
      currentQuantity++;
      if (itemData["item_count"] !== "None" && currentQuantity >= itemData["item_count"]) {
        currentQuantity = parseInt(itemData["item_count"]);
      }
    } else {
      currentQuantity--;
      if (currentQuantity <= 0) {
        currentQuantity = 0;
      }
    }
    setItemQuantity(currentQuantity);
  };

  const goToItemPage = () => {
    const type = itemData.type;
    onProductClick(itemData, itemData.category, itemData.subcategory);
    onProductClickXtreme(itemData, itemData.category, itemData.subcategory, skin);

    if (menuLevel === "threeLevels") {
      if (type === "productItem") {
        history.push(
          `/online-ordering/menu/category/${itemData.category}/subcategory/${itemData.subcategory}/item/${itemData.id}`
        );
      } else if (type === "comboItem") {
        history.push(
          `/online-ordering/menu/category/${itemData.category}/subcategory/${itemData.subcategory}/combo/${itemData.id}`
        );
      }
      //
    } else if (menuLevel === "twoLevels") {
      if (type === "productItem") {
        history.push({
          pathname: `/online-ordering/menu/subcategory/${itemData.subcategory}/item/${itemData.id}`,
          state: { from: "item-card" },
        });
      } else if (type === "comboItem") {
        history.push({
          pathname: `/online-ordering/menu/subcategory/${itemData.subcategory}/combo/${itemData.id}`,
          state: { from: "item-card" },
        });
      }
    }
  };

  const onQuickAddButtonClick = async (isAdding, clickedEditButton) => {
    if (isAdding && !clickedEditButton && itemQuantity < 1) {
      if (itemData.isSuggestedItem) {
        if (itemData.suggestedItemType === "item-level") {
          onAddSuggestedItemXtreme(
            itemData,
            itemData.category,
            itemData.subcategory,
            skin,
            "item-level-merchant"
          );
        } else {
          onAddSuggestedItemXtreme(
            itemData,
            itemData.category,
            itemData.subcategory,
            skin,
            itemData.isSuggestedTwice
              ? "order-level-ai/order-level-merchant"
              : itemData.isAIrecommendation
              ? "order-level-ai"
              : "order-level-merchant"
          );
        }
      }
    }
    
    if (setIsEditingComboItem) {
      setIsEditingComboItem(false);
    }

    if (requiresCustomization && itemQuantity === 0) {
      /** Check if the item has all any addon or modifier groups
       * if so check if those groups have any required selections
       * if so check if there default values set for that item
       */
      if (preSelectedItem) {
        const cartResponse = isComboChildItem
          ? await addComboChildToCombo(preSelectedItem, comboCartItem, foodMenu, isQuantityCombo)
          : await addMenuItemToCart(
              preSelectedItem,
              1,
              true,
              setAreOptionsValid,
              setIsQuantityValid,
              cart,
              cartContext,
              skin,
              appLabels
            );

        if (cartResponse) {
          if (cartResponse.itemAdded) {
            updateItemQuantity(isAdding);
          }
        }
      } else {
        /** Redirect user to item page to select the required customization */
        //goToItemPage();
      }
    } else {
      if (clickedEditButton) {
        setShowCustomizationPopup(true);
      } else {
        clearTimeout(buttonTimerRef.current);
        /** quick add/remove the item to the cart */
        if (isAdding) {
          /** add Item to cart */
          const cartResponse = isComboChildItem
            ? await addComboChildToCombo(
                preSelectedItem ? preSelectedItem : itemData,
                comboCartItem,
                foodMenu,
                isQuantityCombo
              )
            : await addMenuItemToCart(
                preSelectedItem ? preSelectedItem : itemData,
                1,
                true,
                setAreOptionsValid,
                setIsQuantityValid,
                cart,
                cartContext,
                skin,
                appLabels
              );

          if (cartResponse) {
            if (cartResponse.itemAdded) {
              updateItemQuantity(isAdding);
            }
          }
        } else {
          /** Remove item from cart */
          const cartResponse = isComboChildItem
            ? await updateComboChildItemQuantity(
                false,
                itemData,
                Number(itemData["groupMinQty"]),
                comboCartItem,
                true,
                false,
                isQuantityCombo
              )
            : await removeMenuItemFromCart(itemData, cartContext);
          if (cartResponse) {
            if (cartResponse.itemRemoved || cartResponse.itemUpdated) {
              updateItemQuantity(isAdding);
            }
          }

          if (!isComboChildItem) {
            onRemoveProductFromCartXtreme(
              itemData,
              itemData.category,
              itemData.subcategory,
              false,
              skin
            );
          }
        }
      }
    }
    if (btnPlusRef && btnPlusRef.current && isAdding) {
      btnPlusRef.current.focus();
    }
    if (itemQuantity === 1 && !isAdding && btnQuickAddRef && btnQuickAddRef.current) {
      btnQuickAddRef.current.focus();
      
    }
  };

  /**
   *
   * @param {object} item : item to be removed from cart or combo selection
   */
  const handleItemRemoval = async (item) => {
    setIsRemovingAllItems(false);
    setIsRemovingItem(true);

    if (isComboChildItem) {
      const comboResponse = await removeComboChildFromCombo(item, comboCartItem, isQuantityCombo);
      if (comboResponse) {
        if (comboResponse.itemRemoved) {
          setIsRemovingItem(false);
        }
      }
    } else {
      const cartResponse = await removeMenuItemFromCart(item, cartContext, true);
      if (cartResponse) {
        if (cartResponse.itemRemoved) {
          updateItemQuantity(false);
          setIsRemovingItem(false);
        }
      }
      onRemoveProductFromCartXtreme(item, item.category, item.subcategory, false, skin);
    }
    // Reset the state for showing the customization popup if no instance of the item remains in the cart
    if (cart.filter((tempItem) => tempItem.id === item.id).length === 0) {
      setShowCustomizationPopup(false);
    }

    localforage.removeItem(skin + "__activeItemInCustomizationModel");
    window.location.href = window.location.href.replace("?customization-modal", "");
    
    if (itemQuantity === 0 && btnQuickAddRef && btnQuickAddRef.current) {
      btnQuickAddRef.current.focus();
    }
  };

  /**
   * Removes all instances of an item from cart or a combo selection
   */
  const handleAllItemsRemoval = async () => {
    if (isComboChildItem) {
      let comboCartItemCopy = jsonCopy(comboCartItem);

      const equalityFn = (item1, item2) =>
        item1.id === item2.id && item1.group_id === item2.group_id;

      // Call the removeItemsFromArray function with the original array, items to remove, and the custom equality function
      const newListWithItemsRemoved = removeItemsFromArray(
        comboCartItemCopy.combo_child_items,
        relatedComboChildItem,
        equalityFn
      );

      comboCartItemCopy.combo_child_items = newListWithItemsRemoved;
      comboCartItemCopy["updateComboCartItem"] = comboCartItem["updateComboCartItem"];
      comboCartItem.updateComboCartItem(comboCartItemCopy);

      setIsRemovingAllItems(false);
      setShowCustomizationPopup(false);
    } else {
      const cartResponse = await removeAllInstanceOfItemFromCart(itemData, cartContext, skin);
      if (cartResponse) {
        if (cartResponse.itemRemoved) {
          setIsRemovingAllItems(false);
          setShowCustomizationPopup(false);
        }
      }
    }

    localforage.removeItem(skin + "__activeItemInCustomizationModel");
    window.location.href = window.location.href.replace("?customization-modal", "");
  };

  useEffect(() => {
    if (cart || relatedComboChildItem) {
      setItemQuantity(
        isComboChildItem
          ? !!relatedComboChildItem.length
            ? getTotalComboChildQuantity(relatedComboChildItem)
            : 0
          : getCurrentItemQuantityInCart(itemData, cart)
      );
    }
  }, [cart, relatedComboChildItem]);

  /** if options were not valid, (i.e. required selection), go to item's page */
  useEffect(() => {
    if (areOptionsValid === false) {
      goToItemPage();
    }
  }, [areOptionsValid]);

  useEffect(() => {
    localforage.getItem(skin + "__activeItemInCustomizationModel").then((itemId) => {
      if (itemId && itemId === itemData.id) {
        localforage.removeItem(skin + "__activeItemInCustomizationModel");
        setTimeout(() => {
          setIsRemovingAllItems(false);
          setShowCustomizationPopup(true);
        }, 250);
      }
    });
  }, []);

  return (
    <>
      {(itemCount === "None" || Number(itemCount) > 0 || itemCount === undefined) && (
        <>
          <div
            id={itemData.id}
            className={`menu-category-card__quick-add-button-container ${props.isOrderLeveLSuggestedItemButton ? "suggestedItemQuickAdd" : ""}`}
            style={{ backgroundColor: itemNameBackgroundColor }}
          >
            {itemQuantity === null || itemQuantity === 0 ? (
              <>
                {itemQuantity === null ||
                  (itemQuantity === 0 && (
                    <button
                      ref={btnQuickAddRef}
                      type="button"
                      className={`menu-item-quick-button plus ${props.isOrderLeveLSuggestedItemButton? "orderLevelSuggestedItemButtonPlusContainer" : ""}`}
                      onClick={() => {
                        onQuickAddButtonClick(true);
                      }}
                      style={{
                        borderColor: getContrastColor(primaryColor),
                        border:
                          primaryColor === secondaryColor ||
                          itemNameBackgroundColor === primaryColor
                            ? `1px solid ${getContrastColor(primaryColor)}`
                            : "none",
                      }}
                      disabled={
                        (cart.filter((item) => item.id === itemData.id).length >= itemCount ||
                          itemQuantity >= itemCount ||
                          (!!subGroupQuantityReached &&
                            subGroupQuantityReached(itemData["group_id"]))) &&
                        isComboChildItem &&
                        itemQuantity === 0
                      }
                    aria-label={itemQuantity > 0 ? "" : appLabels["order"]["favorite-add-to-order"] + " " + itemData.name}
                    >
                      <IconPlus
                        aria-hidden={true}
                      style={{ color: getContrastColor(primaryColor) }}
                      />
                    </button>
                  ))}
              </>
            ) : (
              <div
                className={`menu-item-quick-button-container withQuantity  ${
                  hasNoAddonModifier ? "withoutCustomization" : "withCustomization"
                }  ${props.isOrderLevelSuggestedItemButton ? "orderLevelSuggestedItemQuickAddButtonContainer" : ""
                } `}
              >
                {!hasNoAddonModifier && (
                  <div
                    className={`menu-item-quick-button-container__edit-quantity ${
                      preSelectedItem ? "" : "withoutPlus"
                    }`}
                    style={{
                      borderColor: getContrastColor(primaryColor),
                      border:
                        primaryColor === secondaryColor
                          ? `1px solid ${getContrastColor(primaryColor)}`
                          : "none",
                    }}
                  >
                    <span className={`menu-item-quick-button-icon-span`}>
                      <IconEdit aria-hidden="true" customStyle={{ stroke: editIconColor }} />
                    </span>

                    <button
                      type="button"
                      className={`menu-item-quick-button plus withQuantity isEditButton`}
                      onClick={() => {
                        onQuickAddButtonClick(true, true);
                      }}
                      disabled={
                        (cart.filter((item) => item.id === itemData.id).length >= itemCount ||
                          itemQuantity >= itemCount ||
                          (!!subGroupQuantityReached &&
                            subGroupQuantityReached(itemData["group_id"]))) &&
                        isComboChildItem &&
                        itemQuantity === 0
                      }
                      aria-label={
                        itemQuantity > 0
                          ? appLabels["general"]["modify"] + " " + itemData.name
                          : appLabels["order"]["favorite-add-to-order"] + " " + itemData.name
                      }
                    ></button>
                  </div>
                )}
                <div
                  className="menu-item-quick-button-container__plus-container"
                  style={{
                    borderColor: getContrastColor(primaryColor),
                  }}
                >
                  {hasNoAddonModifier && (
                    <button
                      type="button"
                      className={`menu-item-quick-button minus`}
                      onClick={() => {
                        onQuickAddButtonClick(false);
                      }}
                      style={{ borderColor: getContrastColor(primaryColor) }}
                      disabled={itemQuantity <= 0}
                      aria-label={
                        itemQuantity > 1
                          ? appLabels["order"]["decrease-quantity-by-one"] +
                            " " +
                            appLabels["general"]["for-text"] +
                            " " +
                            itemData.name +
                            ". " +
                            appLabels["order"]["current-item-quantity-in-cart"].replace(
                              "[item-quantity]",
                              itemQuantity
                            )
                          : appLabels["order"]["remove-from-order"].replace(
                              "[item-name]",
                              itemData.name
                            )
                      }
                    >
                      <IconMinus
                        aria-hidden={true}
                        style={{ color: getContrastColor(primaryColor) }}
                      />
                    </button>
                  )}

                  <button
                    ref={btnPlusRef}
                    type="button"
                    className={`menu-item-quick-button plus withQuantity ${
                      hasNoAddonModifier ? "withoutCustomization" : "withCustomization"
                    }`}
                    onClick={() => {
                      if (preSelectedItem || hasNoAddonModifier) {
                        onQuickAddButtonClick(true);
                      } else {
                        setShowCustomizationPopup(true);
                      }
                    }}
                    style={{
                      borderColor: getContrastColor(primaryColor),
                      border:
                        primaryColor === secondaryColor || itemNameBackgroundColor === primaryColor
                          ? `1px solid ${getContrastColor(primaryColor)}`
                          : "none",
                    }}
                    disabled={
                      (!isQuantityCombo && isComboChildItem) ||
                      (!!onlineQuantityLimit && itemQuantity >= onlineQuantityLimit) ||
                      cart.filter((item) => item.id === itemData.id).length >= itemCount ||
                      itemQuantity >= itemCount ||
                      itemQuantity === 0 ||
                      (!!subGroupQuantityReached &&
                        subGroupQuantityReached(itemData["group_id"]) &&
                        isComboChildItem)
                    }
                    aria-label={
                      itemQuantity > 0
                        ? preSelectedItem || hasNoAddonModifier
                          ? appLabels["order"]["increase-quantity-by-one"] +
                            " " +
                            appLabels["general"]["for-text"] +
                            " " +
                            itemData.name +
                            ". " +
                            appLabels["order"]["current-item-quantity-in-cart"].replace(
                              "[item-quantity]",
                              itemQuantity
                            )
                          : appLabels["general"]["modify"] + " " + itemData.name
                        : appLabels["order"]["favorite-add-to-order"] + " " + itemData.name
                    }
                  >
                    <span
                      className={`menu-item-quick-button-quantity-span plus withQuantity ${
                        preSelectedItem ? "" : "withoutPlus"
                      } `}
                      style={{
                        color: getContrastColor(primaryColor),
                      }}
                    >
                      {Number(itemQuantity)}
                    </span>
                    {(preSelectedItem || hasNoAddonModifier) && (
                      <IconPlus
                        aria-hidden={true}
                        style={{ color: getContrastColor(primaryColor) }}
                      />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>

          {!isQuantityValid && isQuantityValid != null && (
            <DialogModal
              message={appLabels["order"]["invalid-item-quantity-error"]}
              confirmAction={() => {
                setIsQuantityValid(null);
              }}
              resetRemoveDialog={() => {
                setIsQuantityValid(null);
              }}
            />
          )}

          {showCustomizationPopup &&
            !isRemovingAllItems &&
            (cart.filter((item) => item.id === itemData.id).length > 0 ||
              !!relatedComboChildItem.length) && (
              <QuickAddCustomizationModal
                setShowCustomizationPopup={setShowCustomizationPopup}
                setIsRemovingAllItems={setIsRemovingAllItems}
                handleRemoveItem={handleItemRemoval}
                relatedCartItems={
                  isComboChildItem
                    ? relatedComboChildItem
                    : cart.filter((item) => item.id === itemData.id)
                }
                isRemovingItem={isRemovingItem}
                cartData={cartContext}
                comboCartItem={comboCartItem}
                updateActiveItem={updateActiveItem}
                subGroupQuantityReached={subGroupQuantityReached}
                isModifyDisplay={isModifyDisplay}
                setValidateGoingToNextLevel={setValidateGoingToNextLevel}
                triggerSource={triggerSource}
                isSuggestedItemCard={isSuggestedItemCard}
                setUpdateActiveSuggestedItem={setUpdateActiveSuggestedItem}
              />
            )}

          {isRemovingAllItems && !showCustomizationPopup && (
            <DialogModal
              message={appLabels["order"]["remove-all-items-confirmation-modal-message"].replace(
                "[item-name]",
                itemData.name
              )}
              confirmAction={handleAllItemsRemoval}
              isCancelConfirm={true}
              resetRemoveDialog={() => {
                setIsRemovingAllItems(false);
                setShowCustomizationPopup(true);
              }}
            />
          )}
        </>
      )}
    </>
  );
};
export default MenuItemQuickAddButton;
