import React, { useState, useEffect, useContext } from "react";

/** Contexts */
import AppSettingsContext from "../../../../App/AppSettingsContext";
import AppLanguageContext from "../../../../App/AppLanguageContext";
import AppLabelsContext from "../../../../App/AppLabelsContext";

/** Assets */
import { ReactComponent as IconCheck } from "../../../../_common/icons/IconCheck.svg";
import { ReactComponent as IconPlus } from "../../../../_common/icons/IconPlus.svg";
import { ReactComponent as IconMinus } from "../../../../_common/icons/IconMinus.svg";

/** UI Components */
import { AddonOrModifierGroupItems } from "./AddonOrModifierGroupItems";

/** Helpers */
import { toDollars, jsonCopy } from "../../../../_common/helpers";
import { capitalizeWords } from "../../../../_common/stringHelpers";
import { checkNumberOfSelectedItemsInOptionsGroup } from "../../helpers/getMenuData";
import { useLocation } from 'react-router-dom';

export const AddonOrModifierGroup = (props) => {
  const {
    menuItem,
    updateMenuItem,
    topLevelOptionGroup,
    cartItem,
    isInCart,
    setResetOptionsGroup,
    comboChildItem,
    isTheSameChildItem,
    addonsOutOfStock,
    updateActiveOptionGroupIndex,
    displayType,
    setAddToOrderButtonText,
    currentOptionGroupIndex,
    updateOrderingState,
    orderingState,
    isStepByStepOrderingEnabled,
    isEditingComboItem,
    comboCartItem,
    useMenuItemCopy,
  } = props;

  const [confirmedOptions, setConfirmedOptions] = useState(null);
  const [confirmedOptionsText, setConfirmedOptionsText] = useState("");
  const [isBeingSelected, setIsBeingSelected] = useState(false);
  const [showCheckMark, setShowCheckMark] = useState(false);

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);
  const isModifyDisplay = displayType === "cart-modify";

  const collapseByDefault = appSettings["collapse-addon-group-by-default"] === "yes";

  const updateConfirmedOptions = (
    newConfirmedOptions,
    isRemoving = false,
    unselectedOptions = {}
  ) => {
    if (!isRemoving) {
      const isSingleChoice = newConfirmedOptions.id;

      if (isSingleChoice) {
        newConfirmedOptions.isSelected = true;
        setConfirmedOptions(newConfirmedOptions);
      } 
      else {
        //resetPreviouslySelectedOptions(newConfirmedOptions);
        let tempNewConfirmedOptions = {};
  
         
        Object.keys(newConfirmedOptions).forEach((key) => {
          newConfirmedOptions[key].isSelected = true;
          tempNewConfirmedOptions[key] = newConfirmedOptions[key];
        });
        if (unselectedOptions && Object.keys(unselectedOptions).length > 0) {
          //remove the isSelected key from all the unselected options
          Object.keys(unselectedOptions).forEach((key) => {
            delete unselectedOptions[key].isSelected;
            tempNewConfirmedOptions[key] = unselectedOptions[key];
            if (
              unselectedOptions[key].modifierGroups &&
              Object.keys(unselectedOptions[key]).length > 0
            ) {
              const nestedModGroups = unselectedOptions[key].modifierGroups;
              Object.keys(nestedModGroups).forEach((modKey) => {
                delete nestedModGroups[modKey].isSelected;
                if (
                  nestedModGroups[modKey].items &&
                  Object.keys(nestedModGroups[modKey].items).length > 0
                ) {
                  Object.keys(nestedModGroups[modKey].items).forEach((modItemKey) => {
                    delete nestedModGroups[modKey].items[modItemKey].isSelected;
                  });
                }
              });
            }
          });
        }
        setConfirmedOptions(tempNewConfirmedOptions);
      }

      setIsBeingSelected(true);
      setShowCheckMark(true);
    } else {
      const isSingleChoice = newConfirmedOptions.id;

      if (isSingleChoice) {
        delete newConfirmedOptions.isSelected;
      } else {
        //resetPreviouslySelectedOptions(newConfirmedOptions);

        Object.keys(newConfirmedOptions).forEach((key) => {
          delete newConfirmedOptions[key].isSelected;
          if (
            newConfirmedOptions[key].modifierGroups &&
            Object.keys(newConfirmedOptions[key]).length > 0
          ) {
            const nestedModGroups = newConfirmedOptions[key].modifierGroups;
            Object.keys(nestedModGroups).forEach((modKey) => {
              delete nestedModGroups[modKey].isSelected;
              if (
                nestedModGroups[modKey].items &&
                Object.keys(nestedModGroups[modKey].items).length > 0
              ) {
                Object.keys(nestedModGroups[modKey].items).forEach((modItemKey) => {
                  delete nestedModGroups[modKey].items[modItemKey].isSelected;
                });
              }
            });
          }
        });
      }
      setConfirmedOptions(newConfirmedOptions);
      setConfirmedOptionsText("");
      setShowCheckMark(false);
    }
  };

  const { state } = useLocation()
  useEffect(() => {
    if (isBeingSelected || isEditingComboItem) {
      setResetOptionsGroup(false);
    }
  }, [isBeingSelected, isEditingComboItem]);

  // Setup text for confirmed options to be displayed in slider item button
  useEffect(() => {
    if (confirmedOptions) {
      const tempMenuItem = useMenuItemCopy.current === false ? menuItem : jsonCopy(menuItem);
      useMenuItemCopy.current = true;
      const isSingleChoiceOption = confirmedOptions.id;
      const tempConfirmedOptionsText = [];
      if (isSingleChoiceOption) {
        // Confirmed radio button selection
        const optionType = confirmedOptions.type.toLowerCase(); // "addon" or "modifier"

        // Update the main item object which is used for cart
        Object.keys(
          tempMenuItem[optionType + "Groups"][
            optionType +
              "Group-" +
              (confirmedOptions.isStandalone ? confirmedOptions.id : confirmedOptions.parentId)
          ].items
        ).forEach((addonModItemKey) => {
          const addonModItem =
            tempMenuItem[optionType + "Groups"][
              optionType +
                "Group-" +
                (confirmedOptions.isStandalone ? confirmedOptions.id : confirmedOptions.parentId)
            ].items[addonModItemKey];

          if (addonModItem.isSelected && addonModItem.id !== confirmedOptions.id) {
            delete addonModItem.isSelected;
          }
        });

        tempMenuItem[optionType + "Groups"][
          optionType +
            "Group-" +
            (confirmedOptions.isStandalone ? confirmedOptions.id : confirmedOptions.parentId)
        ].items[optionType + "-" + confirmedOptions.id] = confirmedOptions;

        // For display - so user can see their confirmed selection(s)
        const optionPrice = confirmedOptions.price
          ? " " +
            toDollars(
              appSettings["currency-symbol"],
              appSettings["currency-symbol-side"],
              confirmedOptions.price,
              appLanguage
            )
          : "";
        tempConfirmedOptionsText.push(confirmedOptions.name + optionPrice);
      } else {
        // Confirmed checkbox selection(s)
        Object.keys(confirmedOptions).forEach((key) => {
          const optionType = confirmedOptions[key].type.toLowerCase();

          // Update the main item object which is used for cart
          tempMenuItem[optionType + "Groups"][
            optionType +
              "Group-" +
              (confirmedOptions[key].isStandalone
                ? confirmedOptions[key].id
                : confirmedOptions[key].parentId)
          ].items[optionType + "-" + confirmedOptions[key].id] = confirmedOptions[key];

          // For display - so user can see their selection(s)
          const optionPrice = confirmedOptions[key].price
            ? " " +
              toDollars(
                appSettings["currency-symbol"],
                appSettings["currency-symbol-side"],
                confirmedOptions[key].price,
                appLanguage
              )
            : "";
          if (confirmedOptions[key].isSelected) {
            tempConfirmedOptionsText.push(confirmedOptions[key].name + optionPrice);
          }
        });
      }
      updateMenuItem(tempMenuItem);
      const minQuantity = parseInt(topLevelOptionGroup.minQuantity);

      /** This loop considers the quantity for the selected addons to set the 'confirmedOptionsText' correctly */
      let selectedQuantity = 0;
      Object.keys(confirmedOptions).forEach((confirmedKey) => {
        Object.keys(tempMenuItem.addonGroups).forEach((addonGroupKey) => {
          if (
            confirmedOptions[confirmedKey].isSelected &&
            tempMenuItem.addonGroups[addonGroupKey].items[confirmedKey]
          ) {
            selectedQuantity +=
              tempMenuItem.addonGroups[addonGroupKey].items[confirmedKey].quantity;
          }
        });
      });

      if (tempConfirmedOptionsText.length >= minQuantity || selectedQuantity >= minQuantity) {
        setConfirmedOptionsText(capitalizeWords(tempConfirmedOptionsText.join(", ")));
      } else {
        setConfirmedOptionsText("");
      }

      if (!!tempConfirmedOptionsText.join(", ").length) {
        setShowCheckMark(true);
      }
    }
  }, [confirmedOptions]);

  const getComboChildItem = (itemId) => {
    let comboChildItem = null;
    cartItem.combo_child_items.forEach((item) => {
      if (item.id === itemId) {
        comboChildItem = item;
      }
    });
    return comboChildItem;
  };

  useEffect(() => {
    // Selected addons/modifiers from cart modify
    if (menuItem && cartItem && isModifyDisplay) {
      let onlyConsiderDefaultOptions = false;
      if (
        comboCartItem &&
        comboCartItem.combo_child_items &&
        comboCartItem.combo_child_items.filter(
          (tempItem) =>
            tempItem.id === menuItem.id &&
            tempItem.group_id === menuItem.group_id &&
            tempItem.entryKey === menuItem.entryKey &&
            JSON.stringify(tempItem.addonGroups) === JSON.stringify(menuItem.addonGroups) &&
            JSON.stringify(tempItem.modifierGroups) === JSON.stringify(menuItem.modifierGroups)
        ).length === 0
      ) {
        onlyConsiderDefaultOptions = true;
      }

      let currentOptionGroups = { ...menuItem[topLevelOptionGroup.type + "s"] };
      let tempType = "";

      if (cartItem && cartItem.combo_child_items && getComboChildItem(menuItem.id)) {
        if (topLevelOptionGroup.type === "itemGroup") {
          Object.keys(menuItem.modifierGroups).forEach((modKey) => {
            if (modKey.includes(topLevelOptionGroup.id)) {
              currentOptionGroups = getComboChildItem(menuItem.id)["modifierGroups"];
              tempType = "modifierGroup";
            }
          });

          Object.keys(menuItem.addonGroups).forEach((addonKey) => {
            if (addonKey.includes(topLevelOptionGroup.id)) {
              currentOptionGroups = getComboChildItem(menuItem.id)["addonGroups"];
              tempType = "addonGroup";
            }
          });
        } else {
          currentOptionGroups = getComboChildItem(menuItem.id)[topLevelOptionGroup.type + "s"];
        }
      } else {
        if (topLevelOptionGroup.type === "itemGroup") {
          Object.keys(menuItem.modifierGroups).forEach((modKey) => {
            if (modKey.includes(topLevelOptionGroup.id)) {
              currentOptionGroups = menuItem["modifierGroups"];
              tempType = "modifierGroup";
            }
          });

          Object.keys(menuItem.addonGroups).forEach((addonKey) => {
            if (addonKey.includes(topLevelOptionGroup.id)) {
              currentOptionGroups = menuItem["addonGroups"];
              tempType = "addonGroup";
            }
          });
        }
      }

      let currentOptionGroup;
      if (topLevelOptionGroup.type === "itemGroup") {
        currentOptionGroup = currentOptionGroups[tempType + "-" + topLevelOptionGroup.id];
      } else {
        currentOptionGroup =
          currentOptionGroups[topLevelOptionGroup.type + "-" + topLevelOptionGroup.id];
      }

      const tempConfirmedOptions = [];
      if(currentOptionGroup && currentOptionGroup.items){
      Object.keys(currentOptionGroup.items).forEach((key) => {
        const option = currentOptionGroup.items[key];

        if (onlyConsiderDefaultOptions) {
          if (option.isSelected && option.isDefault === "True") {
            tempConfirmedOptions.push(option);
          }
        } else {
          if (option.isSelected) {
            tempConfirmedOptions.push(option);
          }
        }
      });
      }

      setConfirmedOptions(tempConfirmedOptions.length > 0 ? tempConfirmedOptions : false);
    }
  }, []);

  const appLabels = useContext(AppLabelsContext);

  // Instructions text, based on option group's min/max quantity rules
  const [instructionsText, setInstructionsText] = useState("");
  const [mandatoryOptionClass, setMandatoryOptionClass] = useState(
    " item-detail__option-note--mandatory"
  );

  useEffect(() => {
    const minQuantity = topLevelOptionGroup.minQuantity
      ? parseInt(topLevelOptionGroup.minQuantity)
      : 0;
    const maxQuantity = parseInt(topLevelOptionGroup.maxQuantity);
    let instructionText = "";

    if (maxQuantity > 1 && !minQuantity) {
      instructionText = `${
        appLabels["order"]["select-up-to-n-items"].split("[max-quantity]")[0]
      }${maxQuantity}${appLabels["order"]["select-up-to-n-items"].split("[max-quantity]")[1]}`;
      setInstructionsText(instructionText);
      setMandatoryOptionClass("");
    } else if ((minQuantity === 1 && !maxQuantity) || (minQuantity > 1 && !maxQuantity)) {
      instructionText = appLabels["order"]["select-n-items-or-more"].replace(
        "[n-items]",
        minQuantity
      );
      setInstructionsText(instructionText);
      setMandatoryOptionClass(" item-detail__option-note--mandatory");
    } else if (
      (minQuantity === 1 && maxQuantity === 1) ||
      (minQuantity === maxQuantity && minQuantity > 0)
    ) {
      instructionText = `${
        appLabels["order"]["select-n-item"].split("[min-quantity]")[0]
      }${minQuantity}${appLabels["order"]["select-n-item"].split("[min-quantity]")[1]}`;
      setInstructionsText(instructionText);
      setMandatoryOptionClass(" item-detail__option-note--mandatory");
    } else if (minQuantity >= 1 && maxQuantity > 1) {
      instructionText = `${
        appLabels["order"]["select-between-n-and-n-items"].split("[min-quantity]")[0]
      }${minQuantity}${
        appLabels["order"]["select-between-n-and-n-items"]
          .split("[min-quantity]")[1]
          .split("[max-quantity]")[0]
      }${maxQuantity}${
        appLabels["order"]["select-between-n-and-n-items"]
          .split("[min-quantity]")[1]
          .split("[max-quantity]")[1]
      }`;
      setInstructionsText(instructionText);

      setMandatoryOptionClass(" item-detail__option-note--mandatory");
    } else if (minQuantity === 0) {
      instructionText = "(" + appLabels["general"]["optional"] + ")";
      setInstructionsText(instructionText);
      setMandatoryOptionClass("");
    } else {
      setMandatoryOptionClass("");
    }

    if (currentOptionGroupIndex < orderingState.numberOfOptionGroups) {
      setAddToOrderButtonText(instructionText);
    }
  }, [confirmedOptions, currentOptionGroupIndex, orderingState]);

  /** if the item is already in the cart, change the instruction text to the selected addon/modifier item name */
  useEffect(() => {
    if (isInCart) {
      //else if not in the cart
      const tempCopy = jsonCopy(topLevelOptionGroup);
      const topLevelOptions = tempCopy.items;
      let tempInstructionText = [];
      Object.keys(topLevelOptions).forEach((itemKey) => {
        if (topLevelOptions[itemKey].isSelected) {
          tempInstructionText.push(topLevelOptions[itemKey].name);
        }
      });
      setInstructionsText(tempInstructionText.join(","));
      setMandatoryOptionClass("");
    }
  }, [isInCart]);

  const [defaultAddonsChecked, setDefaultAddonChecked] = useState("idle");

  //const [selectDefaults, setSelectDefaults] = useState(true);

  useEffect(() => {
    if (
      defaultAddonsChecked === "idle" &&
      (!isInCart || (!!comboChildItem && !isTheSameChildItem)) && (state && state.from !=="suggested-item-page")
    ) {
      const optionGroupMaxQuantity = parseInt(topLevelOptionGroup.maxQuantity);
      const selectedOptionsCopy = { ...confirmedOptions };
      const tempCopy = jsonCopy(topLevelOptionGroup);
      const topLevelOptions = tempCopy.items;
      let tempInstructionText = [];

      ///* Check the number of already selected addons in confirmedOptions object
      //* If the object did not have any items use 'checkNumberOfSelectedItemsInOptionsGroup' function
      //

      const numberOfAlreadySelectedOptions = confirmedOptions
        ? Object.keys(confirmedOptions).length
        : checkNumberOfSelectedItemsInOptionsGroup(topLevelOptionGroup);

      Object.keys(topLevelOptions).forEach((key) => {
        const option = topLevelOptions[key];
        if (
          option.isDefault === "True" &&
          (option.item_count === "None" ||
            Number(option.item_count) > 0 ||
            !option.hasOwnProperty("item_count")) &&
          ((optionGroupMaxQuantity > 0 &&
            numberOfAlreadySelectedOptions + 1 <= optionGroupMaxQuantity) ||
            ((optionGroupMaxQuantity === 0 || isNaN(optionGroupMaxQuantity)) &&
              checkNumberOfSelectedItemsInOptionsGroup(topLevelOptionGroup) === 0))
          && !((state && (state.source === "order-suggested-item-card" || state.source === "item-level-suggested" || state.from === "suggested-item-page")))
        ) {
          //check to see if the default addon has modifier groups
          // check if any of those nested modifier groups have defaults
          let hasNestedDefault = false;
          if (option.modifierGroups && Object.keys(option.modifierGroups).length > 0) {
            Object.keys(option.modifierGroups).forEach((nestedModifierKey) => {
              const nestedModGroupItems = option.modifierGroups[nestedModifierKey].items;
              Object.keys(nestedModGroupItems).forEach((itemKey) => {
                if (nestedModGroupItems[itemKey].isDefault === "True") {
                  hasNestedDefault = true;
                }
              });
            });
          }

          const type = option.type === "modifier" ? "modifier" : "addon";
          selectedOptionsCopy[`${type}-${option.id}`] = option;
          selectedOptionsCopy[`${type}-${option.id}`].isSelected = true;
          tempInstructionText.push(option.name);

          const hasNestedModifiers =
            option.modifierGroups && Object.keys(option.modifierGroups).length > 0;

          //** Once the default addon is selected, check to see if it has modfier groups
          // * If any of the nested modifier group items is set to Default mark them as selected
          // /
          if (hasNestedModifiers && hasNestedDefault) {
            const nestedModifiers = option.modifierGroups;
            const nesterModifierKeys = Object.keys(nestedModifiers);

            for (let nestedModGroupKey in nesterModifierKeys) {
              const nestedItems =
                option.modifierGroups[nesterModifierKeys[nestedModGroupKey]].items;
              const nestedItemKeys = Object.keys(nestedItems);

              for (let nestedModItemKey in nestedItemKeys) {
                if (nestedItems[nestedItemKeys[nestedModItemKey]].isDefault === "True") {
                  selectedOptionsCopy[`addon-${option.id}`].modifierGroups[
                    `addonModifierGroup-${
                      option.modifierGroups[nesterModifierKeys[nestedModGroupKey]].id
                    }`
                  ].items[`modifier-${nestedItems[nestedItemKeys[nestedModItemKey]].id}`] =
                    nestedItems[nestedItemKeys[nestedModItemKey]];

                  selectedOptionsCopy[`addon-${option.id}`].modifierGroups[
                    `addonModifierGroup-${
                      option.modifierGroups[nesterModifierKeys[nestedModGroupKey]].id
                    }`
                  ].items[
                    `modifier-${nestedItems[nestedItemKeys[nestedModItemKey]].id}`
                  ].isSelected = true;

                  selectedOptionsCopy[`addon-${option.id}`].modifierGroups[
                    `addonModifierGroup-${
                      option.modifierGroups[nesterModifierKeys[nestedModGroupKey]].id
                    }`
                  ].isSelected = true;
                }
              }
            }
          }
        } else {
          // else if there is at least one previousely selected option
          // set the isSelected Flag for those options
          if (option.isSelected) {
            if (option.type === "modifier") {
              selectedOptionsCopy[`modifier-${option.id}`] = option;
              selectedOptionsCopy[`modifier-${option.id}`].isSelected = true;
            } else if (option.type === "addOn") {
              selectedOptionsCopy[`addon-${option.id}`] = option;
              selectedOptionsCopy[`addon-${option.id}`].isSelected = true;
            }
            tempInstructionText.push(option.name);
          }
        }

        if (tempInstructionText.length > 0) {
          setInstructionsText(tempInstructionText.join(","));
          setMandatoryOptionClass("");
        }
      });

      if (Object.entries(selectedOptionsCopy).length > 0) {
        updateConfirmedOptions(selectedOptionsCopy);
      }
      setDefaultAddonChecked(true);
    }
  }, [defaultAddonsChecked, confirmedOptions, isInCart]);

  const [addonModifierGroupExpanded, setAddonModifierGroupExpanded] = useState(true);
  const collapseAddonModifierGroup = () => {
    const currentState = addonModifierGroupExpanded;
    setAddonModifierGroupExpanded(!currentState);
  };

  useEffect(() => {
    if (collapseByDefault && appSettings["enable-step-by-step-ordering"] !== "yes") {
      collapseAddonModifierGroup();
    }
  }, []);

  return (
    <>
      <div
        className="order-item__option-header space-between-wrapper space-between-wrapper--center-aligned"
        id={`${topLevelOptionGroup.id}-header`}
        role="heading"
        aria-label={ ((addonModifierGroupExpanded) ? appLabels["order"]["collapse-section"] : appLabels["order"]["expand-section"]) + 
          (confirmedOptionsText
            ? topLevelOptionGroup.name + ". " + confirmedOptionsText + " " + appLabels["general"]["selected"]
            : topLevelOptionGroup.name + ". " + instructionsText + ".")
        }
      >
        <div className="heading-with-icon">
          {addonModifierGroupExpanded ? (
            <IconMinus className={`icon--minus item-detail__option-note`}  aria-label={appLabels["order"]["collapse-section"] + " " + topLevelOptionGroup.name} />
          ) : (
            <IconPlus
              className={`icon--plus item-detail__option-note`}
              aria-label={appLabels["order"]["expand-section"] + " " + topLevelOptionGroup.name}
            />
          )}
          <h3 className="order-item__option-heading heading-with-icon">
            <span>
              {topLevelOptionGroup.name} {showCheckMark && <IconCheck />}
            </span>
          </h3>
        </div>
        <div className="item-detail__option-text-container">
          {confirmedOptionsText ? (
            <div className="item-detail__option-note">{confirmedOptionsText}</div>
          ) : (
            <div className={`item-detail__option-note${mandatoryOptionClass}`}>
              {instructionsText}
            </div>
          )}
        </div>
        <button
          className="item-detail__form__fieldset-legend-button"
          type="button"
          onClick={collapseAddonModifierGroup}
          aria-label={
            addonModifierGroupExpanded
              ? appLabels["order"]["collapse-section"] +
                " " +
                topLevelOptionGroup.name +
                ". " +
                confirmedOptionsText
                ? ""
                : instructionsText
              : appLabels["order"]["expand-section"] +
                " " +
                topLevelOptionGroup.name +
                ". " +
                confirmedOptionsText ? 
                "" : instructionsText
          }
        ></button>
      </div>

      <AddonOrModifierGroupItems
        menuItem={menuItem}
        menuItemName={menuItem.name}
        topLevelOptionGroup={topLevelOptionGroup}
        updateConfirmedOptions={updateConfirmedOptions}
        instructionsText={instructionsText}
        mandatoryOptionClass={mandatoryOptionClass}
        isInCart={isInCart}
        isBeingSelected={isBeingSelected}
        confirmedOptions={confirmedOptions}
        addonsOutOfStock={addonsOutOfStock}
        isTheSameChildItem={isTheSameChildItem}
        comboChildItem={comboChildItem}
        addonModifierGroupExpanded={addonModifierGroupExpanded}
        displayType={displayType}
        updateActiveOptionGroupIndex={updateActiveOptionGroupIndex}
        updateOrderingState={updateOrderingState}
        orderingState={orderingState}
        isStepByStepOrderingEnabled={isStepByStepOrderingEnabled}
        isEditingComboItem={isEditingComboItem}
        cartItem={cartItem}
        comboCartItem={comboCartItem}
      />
    </>
  );
};
